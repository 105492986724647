<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  pageQuantity: {
    type: Number,
    required: true
  },
  initialPage: {
    type: Number,
    default: 1
  }
});
const emit = defineEmits(['change-page']);

const currentPage = computed(() => props.initialPage);
const expanded = ref(false);

// Функция для смены текущей страницы и эмита события
const goToPage = (page) => {
  if (page !== currentPage.value) {
    currentPage.value = page;
    emit('change-page', page);
    expanded.value = false;
  }
};

// Формирование массива для отображаемых страниц
const visiblePages = computed(() => {
  const pages = [];
  if (props.pageQuantity <= 5) {
    // Если страниц мало, показываем все
    for (let i = 1; i <= props.pageQuantity; i++) {
      pages.push(i);
    }
  } else {
    // Если страниц много, показываем первую, текущую, последнюю и "..."
    if (currentPage.value !== 1) pages.push(1);
    if (currentPage.value > 2) {
      if (currentPage.value > 3) pages.push('...');
      pages.push(currentPage.value - 1);
    }
    pages.push(currentPage.value);
    if (currentPage.value < props.pageQuantity - 1) {
      pages.push(currentPage.value + 1);
      if (currentPage.value < props.pageQuantity - 2) pages.push('...');
    }
    if (currentPage.value !== props.pageQuantity) pages.push(props.pageQuantity);
  }
  return pages;
});
</script>

<template>
  <div class="pagination">
    <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">
      &lt;
    </button>

    <template v-for="(page, index) in visiblePages" :key="index">
      <button
          v-if="page === '...'"
          class="page dots"
          @click="toggleExpanded"
      >
        {{ page }}
      </button>
      <button
          v-else
          :class="['page', { current: page === currentPage }]"
          @click="goToPage(page)"
      >
        {{ page }}
      </button>
    </template>

    <button @click="goToPage(currentPage + 1)" :disabled="currentPage === props.pageQuantity">
      &gt;
    </button>
  </div>
</template>

<style scoped>
.pagination {
  display: flex;
  gap: 5px;
  align-items: center;
}
.page {
  padding: 8px 12px;
  border: none;
  background: #fff;
  cursor: pointer;
  border: 1px solid #ddd;
}
.page.current {
  background: #ff4d4f;
  color: #fff;
}
.page.dots {
  cursor: default;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.visible-page {
  display: none;
}
</style>
