import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_2 = ["onClick"]

import {onMounted, ref} from "vue";

type Props = {
  name: string;
  options: { title: string; action: () => void }[];
  inBurger?: boolean
};


export default /*@__PURE__*/_defineComponent({
  __name: 'dropdown-nav',
  props: {
    name: { default: "Кнопка" },
    options: {},
    inBurger: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const isOpen = ref(false);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const handleAction = (action: any) => {
  isOpen.value = false;
  console.log('ACTION')
  if (typeof action === "function") {
    action();
  }
};

onMounted(() => {
  document.addEventListener('click', (e) => {
    // @ts-ignore
    if (e.target?.offsetParent?.attributes?.id?.value !== id) {
      isOpen.value = false
    }
  })
})

const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown", {'dropdown-in-burger': _ctx.inBurger}]),
    id: id
  }, [
    _createElementVNode("button", {
      onClick: toggleDropdown,
      class: "dropdown-button"
    }, [
      _createTextVNode(_toDisplayString(props.name) + " ", 1),
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "caret" }, null, -1))
    ]),
    (isOpen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                onClick: ($event: any) => (handleAction(option.action))
              }, _toDisplayString(option.title), 9, _hoisted_2))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})