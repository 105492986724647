<script setup lang="ts">
import HeaderWidget from "@/widgets/header-widget/ui/header-widget.vue";
import AboutPage from "@/pages/about-page/ui/about-page.vue";
import MainPage from "@/pages/main-page/ui/main-page.vue";
import FooterWidget from "@/widgets/footer-widget/ui/footer-widget.vue";
import FooterLine from "@/widgets/footer-line/ui/footer-line.vue";
import {useRoute} from "vue-router";

const route = useRoute();
</script>

<template>
  <HeaderWidget :isInner="route.path !== '/'" />
  <router-view />
  <FooterWidget />
  <FooterLine />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
