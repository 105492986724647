<script setup lang="ts">

</script>

<template>
  <div class="footer-line">
    <a class="footer-line-link" href="http://gamma.01sh.ru/"> © 2021   ООО "ГАММА". Все права защищены. </a>
  </div>
</template>

<style scoped>
</style>
