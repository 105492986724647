import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'button-nav',
  props: {
  isActive: Boolean,
},
  emits: ["click"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleClick = () => {
  emit("click");
};

const buttonClass = ref(props.isActive ? "active" : "inactive");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(buttonClass.value),
    onClick: handleClick
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})