<script setup lang="ts">

import Card from "@/entities/card/ui/card.vue";
import {onMounted, ref} from "vue";
import {RealtyObject, useApiRequestCards} from "@/shared/api/gproperty-service";
import CardListSkeleton from "@/shared/ui/card-list-skeleton.vue";

type Props = {
  cards: RealtyObject[];
  isLoading: boolean
};

const props = defineProps<Props>();


</script>

<template>
<!--  <div class="spinner" v-if="isLoading"></div>-->
  <div class="room-cards-wr">
    <template v-if="!props.isLoading">
      <Card v-for="card in props.cards" :commonData="card" :key="card.id" />
    </template>
    <template v-else>
      <CardListSkeleton v-for="i in 7"/>
    </template>
  </div>
</template>

<style scoped>
.spinner {
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  margin-top: 100px;
  margin-bottom: 80px;
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #ff3b3b;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
