import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "top-bar-list-wr" }
const _hoisted_3 = { class: "top-bar-list" }
const _hoisted_4 = {
  height: "50px",
  style: {"fill-rule":"evenodd","clip-rule":"evenodd","stroke-linejoin":"round","stroke-miterlimit":"2"},
  fill: "white",
  version: "1.1",
  viewBox: "0 0 24 24",
  width: "50px",
  "xml:space": "preserve",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:serif": "http://www.serif.com/",
  "xmlns:xlink": "http://www.w3.org/1999/xlink"
}
const _hoisted_5 = {
  class: "burger-menu",
  style: {"display":"block","right":"0px"}
}
const _hoisted_6 = { style: {"margin-top":"80px","color":"#ff3b3b","text-transform":"uppercase","font-weight":"600","font-size":"20px"} }

import ButtonNav from "@/shared/components/button-nav";
import DropdownNav from "@/shared/components/dropdown-nav/ui/dropdown-nav.vue";
import logo from '../../../assets/image/lg1.png';
import innerLogo from '../../../assets/image/logo.png';
import {useRouter} from "vue-router";
import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'header-widget',
  props: {
  isInner: Boolean
},
  setup(__props) {

const props = __props

const isBurgerOpen = ref(false)

const router = useRouter();
const options1 = [
  {
    title: "Торговое помещение",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Аренда', premises_type: 'Торговое помещение'}})
      isBurgerOpen.value = false
    }
  },
  {
    title: "Офисное помещение",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Аренда', premises_type: 'Офисное помещение'}})
      isBurgerOpen.value = false
    }
  },
  {
    title: "Здание",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Аренда', premises_type: 'Здание'}})
      isBurgerOpen.value = false
    }
  }
]

const options2 = [
  {
    title: "Торговое помещение",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Продажа', premises_type: 'Торговое помещение'}})
      isBurgerOpen.value = false
    }
  },
  {
    title: "Офисное помещение",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Продажа', premises_type: 'Офисное помещение'}})
      isBurgerOpen.value = false
    }
  },
  {
    title: "Здание",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Продажа', premises_type: 'Здание'}})
      isBurgerOpen.value = false
    }
  }
]

const openBurger = () => {
  isBurgerOpen.value = !isBurgerOpen.value
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", {
      class: _normalizeClass(["header-main", {'inner-background': props.isInner}]),
      style: {"position":"relative"}
    }, [
      _createElementVNode("img", {
        class: "header-logo",
        src: props.isInner ? _unref(innerLogo) : _unref(logo),
        width: 140,
        height: "140",
        alt: "Гамма",
        onClick: _cache[0] || (_cache[0] = () => _unref(router).push({name: 'home'}))
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", _hoisted_3, [
          _createVNode(_unref(ButtonNav), {
            onClick: _cache[1] || (_cache[1] = () => _unref(router).push({name: 'home'})),
            class: _normalizeClass({'inner-text': props.isInner})
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("Главная")
            ])),
            _: 1
          }, 8, ["class"]),
          _createVNode(_unref(ButtonNav), {
            onClick: _cache[2] || (_cache[2] = () => _unref(router).push({name: 'about'})),
            class: _normalizeClass({'inner-text': props.isInner})
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("О компании")
            ])),
            _: 1
          }, 8, ["class"]),
          _createVNode(DropdownNav, {
            options: options1,
            name: "Аренда",
            class: _normalizeClass({'inner-select-text': props.isInner})
          }, null, 8, ["class"]),
          _createVNode(DropdownNav, {
            options: options2,
            name: "Продажа",
            class: _normalizeClass({'inner-select-text': props.isInner})
          }, null, 8, ["class"]),
          _createVNode(_unref(ButtonNav), {
            onClick: _cache[3] || (_cache[3] = () => _unref(router).push({name: 'catalog', query: {purpose: 'Арендный бизнес'}})),
            class: _normalizeClass({'inner-text': props.isInner})
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("Арендный бизнес")
            ])),
            _: 1
          }, 8, ["class"]),
          _createVNode(_unref(ButtonNav), {
            onClick: _cache[4] || (_cache[4] = () => _unref(router).push({name: 'partners'})),
            class: _normalizeClass({'inner-text': props.isInner})
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createTextVNode("Партнерам")
            ])),
            _: 1
          }, 8, ["class"]),
          _createVNode(_unref(ButtonNav), {
            onClick: _cache[5] || (_cache[5] = () => _unref(router).push({name: 'contacts'})),
            class: _normalizeClass({'inner-text': props.isInner})
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode("Контакты")
            ])),
            _: 1
          }, 8, ["class"])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["burger", {'burger-inner': __props.isInner}]),
        onClick: openBurger
      }, [
        (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[18] || (_cache[18] = [
          _createElementVNode("g", { id: "Icon" }, [
            _createElementVNode("path", { d: "M4,6.75l16,0c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75l-16,0c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75Z" }),
            _createElementVNode("path", { d: "M4,12.75l16,0c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75l-16,0c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75Z" }),
            _createElementVNode("path", { d: "M4,18.75l16,0c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75l-16,0c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75Z" })
          ], -1)
        ])))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["soc-wr", {'inner-text': props.isInner}])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["catalog-btn", {'inner-catalog': __props.isInner}])
        }, [
          _createElementVNode("a", {
            class: _normalizeClass([{'inner-text': props.isInner, 'inner-catalog': __props.isInner}, "catalog"]),
            href: "/catalog"
          }, " Каталог ", 2)
        ], 2),
        _createElementVNode("a", {
          class: _normalizeClass([{'inner-text': props.isInner}, "user-w"]),
          href: "/admin"
        }, null, 2),
        _createElementVNode("a", {
          class: _normalizeClass([{'inner-text': props.isInner}, "phone"]),
          href: "tel:+79779846661"
        }, "+7 (495) 317-66-65", 2),
        _createElementVNode("a", {
          class: _normalizeClass([{'inner-text': props.isInner}, "mail"]),
          href: "mailto:info@aprope.ru"
        }, "info@aprope.ru", 2),
        _createElementVNode("a", {
          class: _normalizeClass([{'inner-text': props.isInner}, "telegram"]),
          href: "tg://resolve?domain=+79633366665"
        }, null, 2),
        _createElementVNode("a", {
          class: _normalizeClass([{'inner-text': props.isInner}, "website"]),
          href: "https://api.whatsapp.com/send?phone=79779846661&text="
        }, null, 2)
      ], 2)
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        onClick: _cache[6] || (_cache[6] = () => isBurgerOpen.value = false),
        class: "close-burger-menu",
        style: {"position":"absolute","right":"20px","top":"10px","color":"#ff3b3b","font-size":"30px","font-weight":"900","cursor":"pointer"}
      }, "X "),
      _createElementVNode("ul", _hoisted_6, [
        _createElementVNode("li", {
          onClick: _cache[7] || (_cache[7] = () => {_unref(router).push({name: 'home'}); isBurgerOpen.value = false}),
          id: "mainLinkBurger",
          style: {"padding":"10px 20px 5px","border-bottom":"1px solid rgba(255,59,59,0.6)","width":"max-content"}
        }, " Главная "),
        _createElementVNode("li", {
          onClick: _cache[8] || (_cache[8] = () => {_unref(router).push({name: 'catalog'}); isBurgerOpen.value = false}),
          class: "cat-burg-btn",
          style: {"padding":"10px 20px 5px","border-bottom":"1px solid rgba(255,59,59,0.6)","width":"max-content"}
        }, " Каталог "),
        _createElementVNode("li", {
          onClick: _cache[9] || (_cache[9] = () => {_unref(router).push({name: 'about'}); isBurgerOpen.value = false}),
          id: "aboutCopmLink",
          style: {"padding":"10px 20px 5px","border-bottom":"1px solid rgba(255,59,59,0.6)","width":"max-content"}
        }, " О компании "),
        _createVNode(DropdownNav, {
          inBurger: "",
          options: options1,
          name: "Аренда",
          class: _normalizeClass({'inner-select-text': props.isInner})
        }, null, 8, ["class"]),
        _createVNode(DropdownNav, {
          inBurger: "",
          options: options2,
          name: "Продажа",
          class: _normalizeClass({'inner-select-text': props.isInner})
        }, null, 8, ["class"]),
        _createElementVNode("li", {
          onClick: _cache[10] || (_cache[10] = () => _unref(router).push({name: 'catalog', query: {purpose: 'Арендный бизнес'}})),
          id: "rentBisBurger",
          style: {"padding":"10px 20px 5px","border-bottom":"1px solid rgba(255,59,59,0.6)","width":"max-content","text-align":"left"}
        }, " Арендный бизнес "),
        _createElementVNode("li", {
          onClick: _cache[11] || (_cache[11] = () => {_unref(router).push({name: 'partners'}); isBurgerOpen.value = false}),
          id: "partnersBurger",
          style: {"padding":"10px 20px 5px","border-bottom":"1px solid rgba(255,59,59,0.6)","width":"max-content"}
        }, " Партнерам "),
        _createElementVNode("li", {
          onClick: _cache[12] || (_cache[12] = () => {_unref(router).push({name: 'contacts'}); isBurgerOpen.value = false}),
          id: "conBurger",
          style: {"padding":"10px 20px 5px","border-bottom":"1px solid rgba(255,59,59,0.6)","width":"max-content"}
        }, " Контакты ")
      ])
    ], 512), [
      [_vShow, isBurgerOpen.value]
    ])
  ], 64))
}
}

})