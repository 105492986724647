<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
  isActive: Boolean,
  filled: Boolean,
});

const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};

const buttonClass = ref(props.isActive ? "active" : "inactive");
const filledClass = ref(props.filled ? "filled" : '')
</script>

<template>
  <button :class="[buttonClass, filledClass]" @click="handleClick">
    <slot></slot>
  </button>
</template>

<style scoped>
button {
  background: none;
  border: 1px solid #ff3c50;
  font-size: 14px;
  color: #3c3c3c;
  cursor: pointer;
  padding: 1px 20px;
  transition: 0.3s;
  border-radius: 5px;
}



button.active {
  color: #3c3c3c;
}

button.inactive {
  color: 3c3c3c;
}

button:hover {
  color: white;
  background: #ff3c50;
}

button.test {
  background: rebeccapurple;
}

.filled {
  color: white;
  background: #ff3c50;
}
</style>
