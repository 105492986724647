import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "filter-inside-wr" }
const _hoisted_2 = { class: "filter-inside" }
const _hoisted_3 = {
  style: {"display":"flex","margin-top":"15px","cursor":"pointer"},
  class: "filter-inside-params"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "custom-selection" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "custom-selection" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "custom-selection" }
const _hoisted_10 = {
  style: {"width":"157px","margin-right":"8px"},
  id: "hintsWr"
}
const _hoisted_11 = {
  key: 0,
  id: "hints",
  style: {"display":"block"},
  class: "street-list"
}
const _hoisted_12 = { class: "catalog-btn-bot-wr" }
const _hoisted_13 = {
  style: {"text-align":"left","margin-top":"20px","width":"1120px","margin-left":"auto","margin-right":"auto","margin-bottom":"-25px"},
  id: "cardsOnPage"
}
const _hoisted_14 = { class: "room-cards-wr" }
const _hoisted_15 = {
  key: 0,
  style: {"display":"flex","width":"100%","justify-content":"center","margin-bottom":"30px"}
}

import {RealtyObject, useApiRequestCards, useApiRequestGetStreet} from "@/shared/api/gproperty-service";
import {onMounted, ref, watch} from "vue";
import Card from "@/entities/card/ui/card.vue";
import CardListSkeleton from "@/shared/ui/card-list-skeleton.vue";
import MetroModal from "@/features/metro-modal/ui/metro-modal.vue";
import MapModal from "@/features/map-modal/ui/map-modal.vue";
import InputDropdown from "@/shared/components/input-dropdown/ui/input-dropdown.vue";
import Pagination from "@/shared/components/pagination/ui/pagination.vue";
import {useRoute, useRouter} from "vue-router";
import {fi} from "vuetify/locale";

type defaultFiltersType = {
  purpose: null | string,
  premises_type: null | string,
  region: null | string,
  street: null | string,
  underground: null | string[]
}

export default /*@__PURE__*/_defineComponent({
  __name: 'catalog-page',
  setup(__props) {



const { result, isLoading, error, makeRequest } = useApiRequestCards();
const { result: resultStreet, isLoading: isLoadingStreet, makeRequest: getStreet } = useApiRequestGetStreet();

const isOpenMetroModal = ref(false)
const isMapModalOpen = ref(false)

const route = useRoute();
const router = useRouter()

const realtyObjList = ref<RealtyObject[]>([])
const streetList = ref<string[]>([])

const defaultFilters = {
  purpose: null,
  premises_type: null,
  region: null,
  street: null,
  underground: null
}


const areaRange = ref({
  minValue: null,
  maxValue: null
});

const priceRange = ref({
  minValue: null,
  maxValue: null
});

const currentPage = ref(1)
const pageQuantity = ref(1)
const filters = ref<defaultFiltersType>({...defaultFilters})

const changePage = async (currentPageValue: number) => {
  await makeRequest({page_number: currentPageValue})
  realtyObjList.value = {...result.value?.result?.realty_object_list as RealtyObject[]}
  await router.push({
    query: {
      page_number: currentPageValue
    }
  });
  currentPage.value = currentPageValue
}


const onSearchClick = async () => {
  currentPage.value = 1
  await router.push({
    query: {
      page_number: 1,
      ...filters.value,
      price_from: priceRange.value.minValue,
      price_to: priceRange.value.maxValue,
      total_area_from: areaRange.value.minValue,
      total_area_to: areaRange.value.maxValue
    }
  });

}

onMounted(async () => {
  await makeRequest({
    page_number: route.query.page_number || 1,
        ...route.query
  })

  filters.value.purpose = route.query.purpose || null
  filters.value.premises_type = route.query.premises_type || null
  filters.value.region = route.query.region || null
  filters.value.street = route.query.street || null
  priceRange.value.minValue = route.query.price_from ?  Number(route.query.price_from) : null
  priceRange.value.maxValue = route.query.price_to ? Number(route.query.price_to) : null
  areaRange.value.minValue = route.query.total_area_from ?  Number(route.query.total_area_from) : null
  areaRange.value.maxValue = route.query.total_area_to ? Number(route.query.total_area_to) : null

  currentPage.value = Number(route.query.page_number || 1)

  realtyObjList.value = {...result.value?.result?.realty_object_list as RealtyObject[]}
  pageQuantity.value = result.value?.result?.page_quantity
})

const showAll = async () => {
  await router.push({
    query: {}
  });
  currentPage.value = 1
}

const showTorg = async () => {
  await router.push({
    query: {
      premises_type: 'Торговое помещение'
    }
  });
  currentPage.value = 1
}

const showOfficce = async () => {
  await router.push({
    query: {
      premises_type: 'Офисное помещение'
    }
  });
  currentPage.value = 1
}

const showHouse = async () => {
  await router.push({
    query: {
      premises_type: 'Здание'
    }
  });
  currentPage.value = 1
}

watch(() => route.query, async (newQuery) => {
  filters.value.purpose = newQuery.purpose || null
  filters.value.premises_type = newQuery.premises_type || null
  filters.value.region = newQuery.region || null
  filters.value.street = newQuery.street || null
  priceRange.value.minValue = newQuery.price_from ?  Number(newQuery.price_from) : null
  priceRange.value.maxValue = newQuery.price_to ? Number(newQuery.price_to) : null
  areaRange.value.minValue = newQuery.total_area_from ?  Number(newQuery.total_area_from) : null
  areaRange.value.maxValue = newQuery.total_area_to ? Number(newQuery.total_area_to) : null
  await makeRequest({
    page_number: newQuery.page_number || 1,
    ...newQuery
  })
  realtyObjList.value = {...result.value?.result?.realty_object_list as RealtyObject[]}
  currentPage.value = newQuery.page_number ? Number(newQuery.page_number) : 1
  pageQuantity.value = result.value?.result?.page_quantity

}, { immediate: true, deep: true });

const onInputStreet = async ({target}: any) => {
  console.log('onInputStreet', target.value)
  if (target.value.length > 3) {
    await getStreet(target.value)
    streetList.value = resultStreet.value?.result
  } else  if (target.value.length === 0) {
    streetList.value = []
  }
}

const onSelectStreet = (e: any) => {
  console.log('onSelectStreet', e.target.innerText)
  filters.value.street = e.target.innerText
}

const onBlurStreet = () => {
  setTimeout(() => {
    streetList.value = []
  }, 200)
}

const onSubmitMetro = (value: string[]) => {
  console.log('onSubmitMetro: ', value)
  if (value.length) {
    filters.value.underground = value
  } else {
    filters.value.underground = null
  }
}


return (_ctx: any,_cache: any) => {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", { class: "catalog-btn-wr" }, [
          _createElementVNode("div", {
            class: "catalog-btn-inside",
            id: "allOffers",
            onClick: showAll
          }, "Все предложения"),
          _createElementVNode("div", {
            class: "catalog-btn-inside",
            id: "commercialPremises",
            onClick: showTorg
          }, "Торговое помещение"),
          _createElementVNode("div", {
            class: "catalog-btn-inside",
            id: "offPremises",
            onClick: showOfficce
          }, "Офисное помещение"),
          _createElementVNode("div", {
            class: "catalog-btn-inside",
            id: "building",
            onClick: showHouse
          }, "Здание")
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_select, {
            modelValue: filters.value.purpose,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value.purpose) = $event)),
            class: "custom-select",
            placeholder: "Цель",
            items: ['Продажа', 'Аренда', 'Арендный бизнес'],
            variant: "outlined"
          }, {
            item: _withCtx(({ item }) => [
              _createElementVNode("div", {
                class: "custom-item",
                onClick: ($event: any) => (filters.value.purpose = item.title)
              }, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ], 8, _hoisted_4)
            ]),
            selection: _withCtx(({ item, index }) => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(InputDropdown, {
            modelValue: areaRange.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((areaRange).value = $event)),
            label: "Площадь, м²",
            style: {"margin-right":"8px"}
          }, null, 8, ["modelValue"]),
          _createVNode(InputDropdown, {
            modelValue: priceRange.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((priceRange).value = $event)),
            label: "Стоимость, ₽",
            style: {"margin-right":"8px"}
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_select, {
            modelValue: filters.value.premises_type,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filters.value.premises_type) = $event)),
            placeholder: "Тип помещения",
            class: "custom-select",
            items: ['Торговое помещение', 'Здание', 'Офисное помещение'],
            variant: "outlined"
          }, {
            item: _withCtx(({ item }) => [
              _createElementVNode("div", {
                class: "custom-item",
                onClick: ($event: any) => (filters.value.premises_type = item.title)
              }, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ], 8, _hoisted_6)
            ]),
            selection: _withCtx(({ item, index }) => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_select, {
            modelValue: filters.value.region,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filters.value.region) = $event)),
            placeholder: "Любой город",
            class: "custom-select",
            items: ['Любой город', 'Москва', 'Подмосковье'],
            variant: "outlined"
          }, {
            item: _withCtx(({ item }) => [
              _createElementVNode("div", {
                class: "custom-item",
                onClick: ($event: any) => (filters.value.region = item.title)
              }, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ], 8, _hoisted_8)
            ]),
            selection: _withCtx(({ item, index }) => [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", {
            class: "main-filter-list-item main-filter-list-item-media",
            id: "metroBtn",
            onClick: _cache[5] || (_cache[5] = () => isOpenMetroModal.value = true)
          }, _toDisplayString(filters.value.underground?.length ? `Выбрано станций: ${filters.value.underground.length}` : 'Станция метро'), 1),
          _createElementVNode("div", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((filters.value.street) = $event)),
              onInput: onInputStreet,
              onBlur: onBlurStreet,
              style: {"outline":"none"},
              class: "main-filter-list-item main-filter-list-item-media",
              type: "text",
              placeholder: "Улица",
              id: "streetControl"
            }, null, 544), [
              [_vModelText, filters.value.street]
            ]),
            (streetList.value?.length)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(streetList.value, (street) => {
                    return (_openBlock(), _createElementBlock("li", {
                      onClick: onSelectStreet,
                      key: street,
                      class: "street-item"
                    }, _toDisplayString(street), 1))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"main-filter-list-item main-filter-list-item-media\" style=\"position:relative;display:none;\" id=\"paybackWr\" data-v-f0cdb24a><span id=\"payback\" style=\"cursor:pointer;width:100%;display:block;\" data-v-f0cdb24a>Окупаемость, лет</span><span class=\"caret\" style=\"position:absolute;right:5px;bottom:13px;\" data-v-f0cdb24a></span><div id=\"paybackModal\" style=\"display:none;width:155px;height:50px;background:white;position:absolute;bottom:-55px;padding:5px;text-align:center;z-index:20;border-radius:4px;border:1px solid rgba(0,0,0,.15);left:0;padding-top:9px;\" data-v-f0cdb24a><input id=\"paybackFrom\" style=\"width:60px;height:30px;margin-right:10px;\" type=\"number\" placeholder=\"От\" data-v-f0cdb24a><input id=\"paybackTo\" style=\"width:60px;height:30px;\" type=\"number\" placeholder=\"До\" data-v-f0cdb24a></div></div>", 1))
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("button", {
            class: "catalog-btn-on-map onmap-btn",
            onClick: _cache[7] || (_cache[7] = () => isMapModalOpen.value = true)
          }, " На карте "),
          _createElementVNode("button", {
            id: "searchBtn",
            class: "catalog-btn-search",
            onClick: onSearchClick
          }, " Поиск ")
        ])
      ])
    ]),
    _cache[11] || (_cache[11] = _createElementVNode("div", {
      class: "red-title red-title-media",
      style: {"text-align":"left","margin-top":"20px","width":"1120px"},
      id: "allOffersCatalog"
    }, " все предложения ", -1)),
    _createElementVNode("div", _hoisted_13, " Найдено предложений: " + _toDisplayString(_unref(result)?.result?.object_quantity), 1),
    _createElementVNode("div", _hoisted_14, [
      (!_unref(isLoading))
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(realtyObjList.value, (card) => {
            return (_openBlock(), _createBlock(Card, {
              commonData: card,
              key: card.id
            }, null, 8, ["commonData"]))
          }), 128))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(7, (i) => {
            return _createVNode(CardListSkeleton)
          }), 64))
    ]),
    (pageQuantity.value > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(Pagination, {
            "page-quantity": pageQuantity.value,
            initialPage: currentPage.value,
            onChangePage: changePage
          }, null, 8, ["page-quantity", "initialPage"])
        ]))
      : _createCommentVNode("", true),
    (isOpenMetroModal.value)
      ? (_openBlock(), _createBlock(MetroModal, {
          key: 1,
          onClose: _cache[8] || (_cache[8] = () => isOpenMetroModal.value = false),
          onSubmit: onSubmitMetro
        }))
      : _createCommentVNode("", true),
    (isMapModalOpen.value)
      ? (_openBlock(), _createBlock(MapModal, {
          key: 2,
          isLoading: _unref(isLoading),
          cards: realtyObjList.value,
          onClose: _cache[9] || (_cache[9] = ($event: any) => (isMapModalOpen.value = false))
        }, null, 8, ["isLoading", "cards"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})