<script setup lang="ts">
import {useRouter} from "vue-router";

const router = useRouter()
</script>

<template>
  <div class="footer-wr">
    <footer class="footer-main">
      <div class="footer-logo">
      </div>
      <div class="footer-nav-wr">
        <div class="footer-nav-title">
          Навигация
        </div>
        <div class="footer-nav-list">
          <div class="footer-list">
            <div id="footerBtn1" @click="() => router.push({name: 'home'})">
              ГЛАВНАЯ
            </div>
            <div id="footerBtn2" @click="() => router.push({name: 'about'})">
              О КОМПАНИИ
            </div>
            <div id="footerBtn3">
              АРЕНДА
            </div>
            <div id="footerBtn4">
              ПРОДАЖА
            </div>
          </div>
          <div class="footer-list">
            <div id="footerBtn5" @click="() => router.push({name: 'partners'})">
              ПАРТНЕРАМ
            </div>
            <div id="footerBtn6">
              СОБСТВЕННИКАМ
            </div>
            <div id="footerBtn7">
              КЛИЕНТАМ
            </div>
            <div id="footerBtn8">
              АРЕНДНЫЙ БИЗНЕС
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="footer-nav-title">
          Контакты
        </div>
        <div class="footer-contacts-list">
          <a class="footer-phone" href="tel:+79779846661">+7 (495) 317-66-65</a>
          <a class="footer-mail" href="mailto:info@aprope.ru">info@aprope.ru</a>
          <a class="footer-adress" href="https://yandex.ru/maps/213/moscow/house/yaroslavskoye_shosse_114/Z04YcQdmT0wDQFtvfXR3d3pqYw==/?ll=37.706694%2C55.866698&z=17.45">г. Москва, Ярославское шоссе 114</a>
        </div>
      </div>
      <div>
        <div style="display: flex; width: 150px; justify-content: space-between; padding-bottom: 10px; border-bottom: 3px solid #ff3b3b; margin-top: 10px;">
          <div class="footer-user"></div>
          <div class="footer-buy"></div>
          <div class="footer-telegram"></div>
          <div class="footer-website"></div>
        </div>
      </div>
    </footer>
<!--    <script src="/site_js/footer.js"></script>-->
  </div>
</template>

<style scoped>
.footer-nav-list {
  text-align: left;
}
.footer-contacts-list {
  text-align: left;
}
</style>
