import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from "vue";
import MainFilter from "@/widgets/main-filter/ui/main-filter.vue";
import CardList from "@/widgets/card-list/ui/card-list.vue";
import {RealtyObject, useApiRequestCards} from "@/shared/api/gproperty-service";

export default /*@__PURE__*/_defineComponent({
  __name: 'main-page',
  setup(__props) {

const { result, isLoading, error, makeRequest } = useApiRequestCards();
const cards = ref<RealtyObject[]>([])

onMounted(async () => {
  const params = {
    best_offer: true
  }
  await makeRequest(params)
  cards.value = {...result.value?.result?.realty_object_list as RealtyObject[]}

})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "home" }, " Аренда и продажа недвижимости в Москве ", -1)),
    _createVNode(MainFilter, {
      isLoading: _unref(isLoading),
      cards: cards.value
    }, null, 8, ["isLoading", "cards"]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "red-title" }, " ЛУЧШИЕ ПРЕДЛОЖЕНИЯ ", -1)),
    _createVNode(CardList, {
      isLoading: _unref(isLoading),
      cards: cards.value
    }, null, 8, ["isLoading", "cards"])
  ], 64))
}
}

})