<script setup lang="ts">
import ButtonNav from "@/shared/components/button-nav";
import DropdownNav from "@/shared/components/dropdown-nav/ui/dropdown-nav.vue";
import logo from '../../../assets/image/lg1.png';
import innerLogo from '../../../assets/image/logo.png';
import {useRouter} from "vue-router";
import {ref} from "vue";

const props = defineProps({
  isInner: Boolean
})

const isBurgerOpen = ref(false)

const router = useRouter();
const options1 = [
  {
    title: "Торговое помещение",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Аренда', premises_type: 'Торговое помещение'}})
      isBurgerOpen.value = false
    }
  },
  {
    title: "Офисное помещение",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Аренда', premises_type: 'Офисное помещение'}})
      isBurgerOpen.value = false
    }
  },
  {
    title: "Здание",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Аренда', premises_type: 'Здание'}})
      isBurgerOpen.value = false
    }
  }
]

const options2 = [
  {
    title: "Торговое помещение",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Продажа', premises_type: 'Торговое помещение'}})
      isBurgerOpen.value = false
    }
  },
  {
    title: "Офисное помещение",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Продажа', premises_type: 'Офисное помещение'}})
      isBurgerOpen.value = false
    }
  },
  {
    title: "Здание",
    action: () => {
      router.push({name: 'catalog', query: {purpose: 'Продажа', premises_type: 'Здание'}})
      isBurgerOpen.value = false
    }
  }
]

const openBurger = () => {
  isBurgerOpen.value = !isBurgerOpen.value
}
</script>

<template>
  <header class="header-main" :class="{'inner-background': props.isInner}" style="position: relative;">
    <img class="header-logo" :src="props.isInner ? innerLogo : logo" :width="140" height="140" alt="Гамма" @click="() => router.push({name: 'home'})" />
    <div class="top-bar-list-wr">
      <ul class="top-bar-list">
        <ButtonNav @click="() => router.push({name: 'home'})" :class="{'inner-text': props.isInner}">Главная</ButtonNav>
        <ButtonNav @click="() => router.push({name: 'about'})"  :class="{'inner-text': props.isInner}">О компании</ButtonNav>
        <DropdownNav :options="options1" name="Аренда" :class="{'inner-select-text': props.isInner}" />
        <DropdownNav :options="options2" name="Продажа" :class="{'inner-select-text': props.isInner}" />
        <ButtonNav @click="() => router.push({name: 'catalog', query: {purpose: 'Арендный бизнес'}})" :class="{'inner-text': props.isInner}">Арендный бизнес</ButtonNav>
        <ButtonNav @click="() => router.push({name: 'partners'})" :class="{'inner-text': props.isInner}">Партнерам</ButtonNav>
        <ButtonNav @click="() => router.push({name: 'contacts'})" :class="{'inner-text': props.isInner}">Контакты</ButtonNav>
      </ul>
    </div>
    <div class="burger" :class="{'burger-inner': isInner}" @click="openBurger">
      <svg
        height="50px"
        style="
          fill-rule: evenodd;
          clip-rule: evenodd;
          stroke-linejoin: round;
          stroke-miterlimit: 2;
        "
        fill="white"
        version="1.1"
        viewBox="0 0 24 24"
        width="50px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:serif="http://www.serif.com/"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g id="Icon">
          <path
            d="M4,6.75l16,0c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75l-16,0c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75Z"
          ></path>
          <path
            d="M4,12.75l16,0c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75l-16,0c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75Z"
          ></path>
          <path
            d="M4,18.75l16,0c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75l-16,0c-0.414,0 -0.75,0.336 -0.75,0.75c0,0.414 0.336,0.75 0.75,0.75Z"
          ></path>
        </g>
      </svg>
    </div>
    <div class="soc-wr" :class="{'inner-text': props.isInner}">
      <div class="catalog-btn" :class="{'inner-catalog': isInner}">
        <a :class="{'inner-text': props.isInner, 'inner-catalog': isInner}" class="catalog" href="/catalog"> Каталог </a>
      </div>
      <a :class="{'inner-text': props.isInner}" class="user-w" href="/admin"></a>
      <a :class="{'inner-text': props.isInner}" class="phone" href="tel:+79779846661">+7 (495) 317-66-65</a>
      <a :class="{'inner-text': props.isInner}" class="mail" href="mailto:info@aprope.ru">info@aprope.ru</a>
      <a :class="{'inner-text': props.isInner}" class="telegram" href="tg://resolve?domain=+79633366665"></a>
      <a
          :class="{'inner-text': props.isInner}"
        class="website"
        href="https://api.whatsapp.com/send?phone=79779846661&amp;text="
      ></a>
    </div>
  </header>
  <div class="burger-menu" style="display: block; right: 0px;" v-show="isBurgerOpen">
    <div
        @click="() => isBurgerOpen = false"
        class="close-burger-menu" style="position: absolute;
                    right: 20px;
                    top: 10px;
                    color: #ff3b3b;
                    font-size: 30px;
                    font-weight: 900;
                    cursor: pointer;">X
    </div>

    <ul style="margin-top: 80px; color: #ff3b3b; text-transform: uppercase; font-weight: 600; font-size: 20px;">
      <li  @click="() => {router.push({name: 'home'}); isBurgerOpen = false}" id="mainLinkBurger" style="padding: 10px 20px 5px; border-bottom: 1px solid rgba(255,59,59,0.6);
    width: max-content;">
        Главная
      </li>
      <li @click="() => {router.push({name: 'catalog'}); isBurgerOpen = false}" class="cat-burg-btn" style="padding: 10px 20px 5px; border-bottom: 1px solid rgba(255,59,59,0.6);
    width: max-content;">
        Каталог
      </li>
      <li @click="() => {router.push({name: 'about'}); isBurgerOpen = false}" id="aboutCopmLink" style="padding: 10px 20px 5px; border-bottom: 1px solid rgba(255,59,59,0.6);
    width: max-content;">
        О компании
      </li>

      <DropdownNav inBurger :options="options1" name="Аренда" :class="{'inner-select-text': props.isInner}" />

      <DropdownNav inBurger :options="options2" name="Продажа" :class="{'inner-select-text': props.isInner}" />

      <li @click="() => router.push({name: 'catalog', query: {purpose: 'Арендный бизнес'}})" id="rentBisBurger" style="padding: 10px 20px 5px; border-bottom: 1px solid rgba(255,59,59,0.6); width: max-content; text-align: left">
        Арендный бизнес
      </li>
      <li @click="() => {router.push({name: 'partners'}); isBurgerOpen = false}" id="partnersBurger" style="padding: 10px 20px 5px; border-bottom: 1px solid rgba(255,59,59,0.6);
    width: max-content;">
        Партнерам
      </li>
      <li @click="() => {router.push({name: 'contacts'}); isBurgerOpen = false}" id="conBurger" style="padding: 10px 20px 5px; border-bottom: 1px solid rgba(255,59,59,0.6);
    width: max-content;">
        Контакты
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.burger-inner {
  background-color: #ff3b3b !important;
}
 .inner-text {
   color: #3d3d3d !important;
   .user-w {
     background: url(../../../assets/image/user.svg) right no-repeat;
   }
   .user-w:hover {
     background: url(../../../assets/image/userhover.svg) right no-repeat;
   }
   .phone::before {
     content: '';
     display: inline-block;
     background: url(../../../assets/image/phone.svg) right no-repeat;
     background-size: cover;
     width: 20px;
     height: 20px;
     position: absolute;
     left: -30px;
     transition: 0.3s ease;
   }
   .phone:hover::before {
     content: '';
     display: inline-block;
     background: url(../../../assets/image/phone2_w.svg) right no-repeat;
     background-size: cover;
     width: 20px;
     height: 20px;
     position: absolute;
     left: -30px;
     transition: 0.3s ease;
   }
   .mail::before {
     content: '';
     display: inline-block;
     background: url(../../../assets/image/mail.svg) right no-repeat;
     background-size: cover;
     width: 20px;
     height: 20px;
     position: absolute;
     left: -30px;
     transition: 0.3s ease;
   }
   .mail:hover::before {
     content: '';
     display: inline-block;
     background: url(../../../assets/image/mail_hover.svg) right no-repeat;
     background-size: cover;
     width: 20px;
     height: 20px;
     position: absolute;
     left: -30px;
     transition: 0.3s ease;
   }
 }
 .inner-text:hover {
   color: #ff3b3b !important;
 }
 .inner-background {
   background: white;
 }
 .inner-select-text {
   :deep(.dropdown-button) {
     color: #3d3d3d !important;
   }
   :deep(.dropdown-button:hover) {
     color: #ff3b3b !important;
   }
 }

 .inner-catalog {
     background-color: #ff3b3b !important;
      color: white !important;
   }

 .inner-catalog:hover {
   color: white !important;
   opacity: 0.8;
 }

 .catalog:hover {
   color: white;
 }

</style>
