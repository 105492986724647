<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
  isActive: Boolean,
});

const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};

const buttonClass = ref(props.isActive ? "active" : "inactive");
</script>

<template>
  <button :class="buttonClass" @click="handleClick">
    <slot></slot>
  </button>
</template>

<style scoped>
button {
  background: none;
  border: none;
  font-size: 16px;
  color: white;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s;
}

button.active {
  color: #ff3c50;
}

button.inactive {
  color: white;
}

button:hover {
  color: #ff3c50;
}
</style>
