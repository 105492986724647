import { ref } from 'vue';

export function useApiRequestCards() {
    const result = ref<any>(null);
    const isLoading = ref(false);
    const error = ref<Error | null>(null);

    const makeRequest = async (params: any) => {
        const url = 'https://gproperty.ru/api/call_pg_func';
        const authToken = '410bcc80129510d08e8f190eac7a670e'; // Замените на актуальный токен
        const payload = {
            method: "realty_object_data_for_site",

            params: params
        };

        isLoading.value = true;
        error.value = null;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Accept-Encoding': 'gzip, deflate, br, zstd',
                    'Accept-Language': 'ru,en;q=0.9',
                    'Auth-Token': authToken,
                    'Cache-Control': 'no-cache',
                    'Connection': 'keep-alive',
                    'Content-Type': 'application/json',
                    'Origin': 'https://gproperty.ru',
                    'Pragma': 'no-cache',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`Ошибка: ${response.status}`);
            }

            const data = await response.json();
            result.value = data;
        } catch (err) {
            error.value = err as Error;
            console.error('Ошибка запроса:', error.value);
        } finally {
            isLoading.value = false;
        }
    };

    return {
        result,
        isLoading,
        error,
        makeRequest,
    };
}
