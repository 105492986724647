<script setup lang="ts">

import {onMounted} from "vue";

const emit = defineEmits(['close, submit'])

const allStationsOnRingList = ['s83', 's82', 's102','s81','s6','s80','s53','s174','s116','s135','s79','s78','s31','s77','s148','s76','s97','s75','s13','s74','s49','s70','s85','s120','s84','s26','s153']
const allStationsInRingList = ['s83', 's82', 's102', 's81', 's6', 's80', 's53', 's174', 's116', 's135',
  's79', 's78', 's31', 's77', 's148', 's76', 's97', 's75', 's13', 's74',
  's49', 's70', 's85', 's120', 's84', 's26', 's153', 's101', 's100', 's99',
  's98', 's7', 's8', 's9', 's10', 's11', 's12', 's175', 's176', 's149',
  's150', 's151', 's152', 's117', 's118', 's119', 's27', 's28', 's29', 's30',
  's50', 's51', 's52', 's136', 's71', 's72', 's73']
const inMckList = ['s83', 's82', 's102', 's81', 's6', 's80', 's53', 's174', 's116', 's135',
  's79', 's78', 's31', 's77', 's148', 's76', 's97', 's75', 's13', 's74',
  's49', 's70', 's85', 's120', 's84', 's26', 's153', 's101', 's100', 's99',
  's98', 's7', 's8', 's9', 's10', 's11', 's12', 's175', 's176', 's149',
  's150', 's151', 's152', 's117', 's118', 's119', 's27', 's28', 's29', 's30',
  's50', 's51', 's52', 's136', 's71', 's72', 's73', 's103', 's104', 's105',
  's106', 's227', 's226', 's225', 's230', 's1', 's2', 's3', 's4', 's5', 's224',
  's223', 's54', 's55', 's56', 's222', 's132', 's133', 's134', 's263', 's221',
  's220', 's219', 's218', 's217', 's115', 's172', 's173', 's171', 's216', 's215',
  's32', 's214', 's213', 's147', 's96', 's95', 's211', 's210', 's14', 's231',
  's66', 's67', 's69', 's193', 's235', 's209', 's68', 's208', 's236', 's121',
  's122', 's123', 's237', 's207', 's206', 's205', 's204', 's203', 's205', 's23',
  's24', 's25', 's238', 's202', 's201', 's229', 's154', 's155', 's156', 's157',
  's228', 's158', 's234', 's233', 's232', 's178', 's177', 's212']
const inCaoList = [
  's83', 's82', 's102', 's81', 's6', 's80', 's53', 's174', 's116', 's135',
  's79', 's78', 's31', 's77', 's148', 's76', 's97', 's75', 's13', 's74',
  's49', 's70', 's85', 's120', 's84', 's26', 's153', 's101', 's100', 's99',
  's98', 's7', 's8', 's9', 's10', 's11', 's12', 's175', 's176', 's149',
  's150', 's151', 's152', 's117', 's118', 's119', 's27', 's28', 's29', 's30',
  's50', 's51', 's52', 's136', 's71', 's72', 's73', 's177', 's103', 's5',
  's55', 's54', 's134', 's173', 's115', 's172', 's16', 's15', 's14', 's68',
  's208', 's236', 's69', 's193', 's235', 's121', 's122'
]

const saveMetro = () => {

  const textElements = document.querySelectorAll('text');

  const correspondences = {};

  textElements.forEach((element) => {
    const className = element.getAttribute('class');
    const textValue = element.textContent;

    const classList = className.split(' ');

    classList.forEach((cls) => {
      correspondences[cls] = textValue;
    });
  });

  let stanList = document.querySelectorAll('[id^="s"]')
  const currentValue = Array.from(stanList).filter(el => el.classList.contains('active-st')).length
    ? Array.from(stanList).filter(el => el.classList.contains('active-st')).map(el => correspondences[el.id])
    : []
  emit('submit', currentValue)
  emit('close')

}

onMounted(() => {
  let metroWr = document.querySelector('.metro-wr');
  let metroWrBack = document.querySelector('.metro-wr-back');
  let closeMetro = document.querySelector('.close-metro');

  let stanList = document.querySelectorAll('[id^="s"]')

  const removeActiveStations = () => {
    for (let i = 0; i < stanList.length; i++) {
      if (stanList[i].localName == 'circle') {
        stanList[i].classList.remove('active-st');
      }
    }
  }
  let stanListText = document.querySelectorAll('[class^="s"]')

  for (let i = 0; i < stanList.length; i++) {
    if (stanList[i].localName == 'circle') {
      stanList[i].addEventListener('mouseover', () => {
        stanList[i].style.cursor = 'pointer';
      })
      stanList[i].addEventListener('click', () => {
        if (stanList[i].classList.contains('active-st')) {
          for (let j = 0; j < stanListText.length; j++) {
            if (stanListText[j].localName == 'text' && stanListText[j].classList.contains(stanList[i].id)) {
              for (let k = 0; k < stanListText[j].classList.length; k++) {
                for (let l = 0; l < stanList.length; l++) {
                  if (stanList[l].id == stanListText[j].classList[k]) {
                    stanList[l].classList.remove('active-st');
                  }
                }
              }
            }
          }
        } else {
          for (let j = 0; j < stanListText.length; j++) {
            if (stanListText[j].localName == 'text' && stanListText[j].classList.contains(stanList[i].id)) {
              for (let k = 0; k < stanListText[j].classList.length; k++) {
                for (let l = 0; l < stanList.length; l++) {
                  if (stanList[l].id == stanListText[j].classList[k]) {
                    stanList[l].classList.add('active-st');
                  }
                }
              }
            }
          }
        }
      })
    }
  }

  let cleanMetro = document.querySelector('#cleanMetro');
  let saveMetro = document.querySelector('#saveMetro');

  cleanMetro?.addEventListener('click', () => {
    removeActiveStations()
  })

  let allStationsOnRing = document.querySelector('#allStationsOnRing');
  let allStationsInRing = document.querySelector('#allStationsInRing');
  let inMck = document.querySelector('#inMck');
  let inCao = document.querySelector('#inCao');

  allStationsOnRing?.addEventListener('click', () => {
    removeActiveStations()
    for (let i = 0; i < stanList.length; i++) {
      if (stanList[i].localName == 'circle') {
        if (allStationsOnRingList.includes(stanList[i].id)) {
          stanList[i].classList.add('active-st');
        }
      }
    }
  })

  allStationsInRing?.addEventListener('click', () => {
    removeActiveStations()
    for (let i = 0; i < stanList.length; i++) {
      if (stanList[i].localName == 'circle') {
        if (allStationsInRingList.includes(stanList[i].id)) {
          stanList[i].classList.add('active-st');
        }
      }
    }
  })

  inMck?.addEventListener('click', () => {
    removeActiveStations()
    for (let i = 0; i < stanList.length; i++) {
      if (stanList[i].localName == 'circle') {
        if (inMckList.includes(stanList[i].id)) {
          stanList[i].classList.add('active-st');
        }
      }
    }
  })

  inCao?.addEventListener('click', () => {
    removeActiveStations()
    for (let i = 0; i < stanList.length; i++) {
      if (stanList[i].localName == 'circle') {
        if (inCaoList.includes(stanList[i].id)) {
          stanList[i].classList.add('active-st');
        }
      }
    }
  })


})
</script>

<template>
  <div class="metro-wr-back" style="display: block;"></div>
  <div class="metro-wr" style="display: block;">
    <div class="close-metro" @click="$emit('close')">x</div>
    <div style="font-size: 28px; padding-top: 10px;">Станция метро</div>
    <div style="font-size: 18px;">Выберите станцию метро на карте</div>
    <div style="display: flex; justify-content: space-between; margin-top: 20px;">
      <div style="padding-left: 25px; text-align: left; width: 350px;">
        <div id="allStationsOnRing" class="metro-filter-item">Все станции на садовом кольце</div>
        <div id="allStationsInRing" class="metro-filter-item">Все станции внутри садового кольца</div>
        <div id="inMck" class="metro-filter-item">В пределах МЦК</div>
        <div id="inCao" class="metro-filter-item">ЦАО</div>
      </div>
      <div class="metro-btn-wr">
        <button id="saveMetro" @click="saveMetro">СОХРАНИТЬ</button>
        <button id="cleanMetro">ОЧИСТИТЬ</button>
      </div>
    </div>

    <svg version="1.1" id="mosmetro" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" preserveAspectRatio="xMidYMid slice" viewBox="0 0 1366 1650" width="946" height="1142">
      <g class="lines" stroke-linecap="round" stroke-width="8">
        <g class="ln16" style="stroke: #7acdce">
          <line class="s132 s133" x1="1130" y1="715" x2="1095" y2="680"></line>
        </g>
        <g class="ln15">
          <line class="s237 s238" x1="289" y1="584" x2="380" y2="493"></line>
          <line class="s238 s239" x1="380" y1="493" x2="414" y2="459"></line>
          <g fill="none">
            <path class="s236 s237" d="M288.4 584.5l-39.3 39.3c-7.1,8.6-10.4,17-10.1,25.3l0 40"></path>
            <path class="s235 s236" d="M239 689.2l0 49.8c-0.6,10 6.3,14.7 20.7,14.3"></path>
          </g>
        </g>
        <g class="ln5" fill="none">
          <path class="s83 s82" d="M577 465.1c37.9,-20.7 80.2,-32.2 123.4,-33.3 36.2,-0.9 72.2,5.3 105.9,18.5"></path>
          <path class="s84 s83" d="M473.8 564.8c24.8,-41.8 60.6,-76.3 103.2,-99.6"></path>
          <path class="s85 s84" d="M439.9 655.4c5.7,-32 17.2,-62.7 33.8,-90.7"></path>
          <path class="s74 s85" d="M439.4 748.4c-2.1,-12.5-3.3,-25.1-3.6,-37.8-0.5,-18.5 0.9,-37 4.2,-55.3"></path>
          <path class="s75 s74" d="M492.7 870.2c-27.6,-35.5-45.9,-77.4-53.3,-121.7"></path>
          <path class="s76 s75" d="M547 923c-20.4,-14.9-38.7,-32.8-54.2,-52.8"></path>
          <path class="s77 s76" d="M634.9 965.5c-31.7,-8.8-61.7,-23.4-88.3,-42.9"></path>
          <path class="s78 s77" d="M830.9 945.8c-36.1,18.4-75.8,28.5-116.3,29.5-26.9,0.7-53.9,-2.6-79.8,-9.8"></path>
          <path class="s79 s78" d="M959.6 805.4c-24.5,60.7-70.3,110.7-128.7,140.4"></path>
          <path class="s80 s79" d="M973.5 647.5c3.4,16 5.3,32.5 5.8,49 1,37.3-5.8,74.4-19.7,108.9"></path>
          <path class="s81 s80" d="M921.7 536.2c25.5,32.6 43.3,70.7 51.8,111.3"></path>
          <path class="s81 s82" d="M806.3 450.3c45.5,17.7 85.4,47.5 115.5,85.9"></path>
        </g>
        <g class="ln14" fill="none">
          <path class="s228 s227" d="M621.4 219.1c28.1,-4.9 56.8,-7.4 85.4,-7.4 54.5,0 108.7,9.1 160.2,26.8"></path>
          <path class="s229 s228" d="M558.9 234.5c20.4,-6.4 41.5,-11.7 62.5,-15.3"></path>
          <path class="s201 s229" d="M496.6 258.9c20.1,-9.5 41.1,-17.8 62.3,-24.5"></path>
          <path class="s201 s202" d="M438.8 291.1c18.4,-12 37.8,-22.8 57.7,-32.1"></path>
          <path class="s204 s203" d="M316.5 404.2c9.6,-12.4 19.8,-24.6 30.5,-36"></path>
          <path class="s205 s204" d="M268.4 480.4c13.6,-26.7 29.8,-52.3 48.1,-76.1"></path>
          <path class="s208 s207" d="M215 689.2c1.1,-39.5 7.2,-78.8 17.7,-116.9"></path>
          <path class="s209 s208" d="M216.3 742.4c-1,-12.8-1.5,-25.8-1.5,-38.6 0,-4.9 0.1,-9.8 0.2,-14.6"></path>
          <path class="s231 s209" d="M223 793.5c-3.1,-16.8-5.4,-34-6.7,-51.1"></path>
          <path class="s210 s231" d="M338.3 1029.9c-59.1,-66.9-99.1,-148.6-115.3,-236.4"></path>
          <path class="s211 s210" d="M464.5 1132.1c-47.4,-26.8-90.2,-61.4-126.3,-102.2"></path>
          <path class="s212 s211" d="M550.7 1170.5c-29.8,-10-58.8,-22.9-86.1,-38.4"></path>
          <path class="s213 s212" d="M694 1195.7c-48.7,-1.3-97.1,-9.8-143.3,-25.2"></path>
          <path class="s214 s213" d="M783.8 1189.8c-25.4,4-51.2,6.1-77,6.1-4.3,0-8.7,-0.1-12.9,-0.2"></path>
          <path class="s215 s214" d="M845 1176.1c-20,5.8-40.6,10.5-61.2,13.7"></path>
          <path class="s216 s215" d="M965.4 1122.5c-37.5,23.1-78.2,41.3-120.5,53.6"></path>
          <path class="s218 s217" d="M1168.3 874.5c-25.9,70-67.5,133.4-121.5,185"></path>
          <path class="s219 s218" d="M1182.8 828.7c-4,15.4-9,30.9-14.5,45.8"></path>
          <path class="s220 s219" d="M1196.6 750.9c-2.5,26.1-7.1,52.3-13.8,77.7"></path>
          <path class="s221 s220" d="M1198.5 684c0.2,6.5 0.4,13.2 0.4,19.8 0,15.7-0.8,31.5-2.3,47.2"></path>
          <path class="s224 s223" d="M1013.2 318.7c47.1,37.5 87.1,83.4 117.7,135.4"></path>
          <path class="s230 s224" d="M977.7 293c12.1,8 24,16.6 35.4,25.7"></path>
          <path class="s225 s230" d="M939.4 270.1c12.9,6.8 25.4,14.4 37.6,22.4"></path>
          <path class="s226 s225" d="M907 254.2c10.9,4.9 21.8,10.2 32.4,15.9"></path>
          <path class="s227 s226" d="M867.1 238.5c13.5,4.6 26.9,10 40,15.7"></path>
          <path class="s222 s221" d="M1185.7 590.6c7.2,30.6 11.5,62 12.8,93.4"></path>
          <path class="s222 s223" d="M1131.7 455.5c24.6,42.1 42.8,87.7 54,135.1"></path>
          <path class="s216 s217" d="M1046.8 1059.6c-24.6,23.5-51.8,44.5-80.7,62.4"></path>
          <path class="s203 s202" d="M346.9 368.2c27.3,-29.3 58.3,-55.3 91.9,-77.1"></path>
          <path class="s206 s207" d="M233.8 568.1c2.3,-8.2 5,-16.5 7.7,-24.6"></path>
          <path class="s205 s206" d="M241.5 543.6c7.5,-21.6 16.5,-42.8 26.9,-63.2"></path>
        </g>
        <circle fill="none" stroke="white" stroke-width="1.5" cx="707" cy="703.5" r="492"></circle>
        <g class="ln1">
          <line class="s1 s2" x1="999" y1="272" x2="999" y2="335"></line>
          <line class="s2 s3" x1="999" y1="335" x2="999" y2="376"></line>
          <line class="s3 s4" x1="999" y1="376" x2="999" y2="415"></line>
          <line class="s5 s6" x1="972" y1="464" x2="922" y2="515"></line>
          <line class="s6 s7" x1="922" y1="515" x2="880" y2="557"></line>
          <line class="s7 s8" x1="880" y1="557" x2="842" y2="595"></line>
          <line class="s8 s9" x1="842" y1="595" x2="777" y2="660"></line>
          <line class="s9 s10" x1="777" y1="660" x2="709" y2="728"></line>
          <line class="s10 s11" x1="709" y1="727" x2="626" y2="810"></line>
          <line class="s11 s12" x1="626" y1="810" x2="565" y2="871"></line>
          <line class="s15 s16" x1="313" y1="1068" x2="313" y2="1102"></line>
          <line class="s16 s17" x1="313" y1="1102" x2="313" y2="1156"></line>
          <line class="s17 s18" x1="313" y1="1156" x2="313" y2="1197"></line>
          <line class="s18 s19" x1="313" y1="1197" x2="313" y2="1251"></line>
          <line class="s19 s196" x1="313" y1="1251" x2="313" y2="1292"></line>
          <line class="s196 s199" x1="313" y1="1292" x2="313" y2="1334"></line>
          <line class="s199 s200" x1="313" y1="1334" x2="313" y2="1490"></line>
          <line class="s199 s200" x1="313" y1="1490" x2="147" y2="1490"></line>
          <g fill="none">
            <path class="s4 s5" d="M972.4 464.4l14.8-14.9c7.8,-7.8 11.5,-15.9 11.3,-24.3l0-10"></path>
            <path class="s12 s13" d="M565.4 871.4l-4.7 4.7c-8.9,9.3-19.3,13.8-31.1,13.4l-45.5 0"></path>
            <path class="s13 s14" d="M357.9 928.4l27.1-27.1c7.7,-8 16.6,-11.9 26.6,-11.8l72.5 0"></path>
            <path class="s14 s15" d="M357.9 928.4l-35.4 35.4c-6.7,6.6-10,13.7-9.7,21.4l0 82.7"></path>
          </g>
        </g>
        <g class="ln2">
          <line class="s20 s21" x1="369" y1="105" x2="369" y2="220"></line>
          <line class="s21 s22" x1="369" y1="220" x2="369" y2="316"></line>
          <line class="s22 s23" x1="369" y1="316" x2="369" y2="386"></line>
          <line class="s23 s24" x1="369" y1="386" x2="369" y2="414"></line>
          <line class="s25 s26" x1="414" y1="482" x2="474" y2="542"></line>
          <line class="s26 s27" x1="474" y1="542" x2="508" y2="577"></line>
          <line class="s27 s28" x1="508" y1="577" x2="592" y2="661"></line>
          <line class="s30 s31" x1="818" y1="861" x2="818" y2="964"></line>
          <line class="s31 s32" x1="818" y1="964" x2="818" y2="1140"></line>
          <line class="s32 s198" x1="818" y1="1140" x2="818" y2="1212"></line>
          <line class="s198 s33" x1="818" y1="1251" x2="818" y2="1212"></line>
          <line class="s33 s34" x1="818" y1="1251" x2="818" y2="1302"></line>
          <line class="s34 s35" x1="818" y1="1302" x2="818" y2="1348"></line>
          <line class="s35 s36" x1="818" y1="1348" x2="818" y2="1381"></line>
          <line class="s36 s37" x1="818" y1="1381" x2="818" y2="1421"></line>
          <line class="s37 s38" x1="818" y1="1421" x2="818" y2="1461"></line>
          <line class="s39 s187" x1="1002" y1="1504" x2="1134" y2="1504"></line>
          <g fill="none">
            <path class="s24 s25" d="M369.2 413.9l0 10.8c-0.2,8.6 2.4,15.6 8,21.1l36.3 36.3"></path>
            <path class="s28 s29" d="M592.3 660.9l76.4 76.4c6.5,7 15,10.3 25.6,10.1l23.1 0"></path>
            <path class="s29 s30" d="M717.4 747.4l24 0c9,-0.2 16.8,3.2 23.4,10.2l43.6 43.6c7.2,7 10.6,14.6 10.1,23l0 36.8"></path>
            <path class="s38 s39" d="M1002.5 1504.4l-155.3 0c-20,-0.1-29.6,-9.7-28.7,-28.7l0-14.8"></path>
          </g>
        </g>
        <g class="ln3">
          <line class="s188 s40" x1="54" y1="271" x2="54" y2="316"></line>
          <line class="s40 s41" x1="54" y1="316" x2="54" y2="351"></line>
          <line class="s41 s42" x1="54" y1="351" x2="54" y2="387"></line>
          <line class="s42 s43" x1="54" y1="387" x2="54" y2="423"></line>
          <line class="s43 s44" x1="54" y1="423" x2="54" y2="458"></line>
          <line class="s44 s45" x1="54" y1="458" x2="54" y2="493"></line>
          <line class="s45 s46" x1="54" y1="493" x2="54" y2="528"></line>
          <line class="s47 s48" x1="174" y1="848" x2="108" y2="848"></line>
          <line class="s49 s50" x1="405" y1="767" x2="502" y2="767"></line>
          <line class="s50 s51" x1="502" y1="767" x2="601" y2="767"></line>
          <line class="s51 s52" x1="601" y1="767" x2="726" y2="767"></line>
          <line class="s53 s54" x1="973" y1="669" x2="1032" y2="611"></line>
          <line class="s54 s55" x1="1032" y1="611" x2="1079" y2="564"></line>
          <line class="s55 s56" x1="1079" y1="564" x2="1127" y2="516"></line>
          <line class="s57 s58" x1="1156" y1="427" x2="1156" y2="375"></line>
          <line class="s58 s59" x1="1156" y1="375" x2="1156" y2="322"></line>
          <line class="s59 s60" x1="1156" y1="322" x2="1156" y2="272"></line>
          <g fill="none">
            <path class="s46 s47" d="M54.4 527.9l-0 291.3c-0.2,19.5 10.2,29.1 31,28.8l22.9 0"></path>
            <path class="s48 s49" d="M173.6 848l110.3 0c10.6,-0.1 19.5,-3.9 26.7,-11.2l56.6-56.6c8.2,-9.1 17.5,-13.4 28.1,-12.9l9.9 0"></path>
            <path class="s52 s53" d="M973.3 668.9l-86.3 86.3c-9.2,8.4-18.3,12.4-27.2,12.1l-133.9 0"></path>
            <path class="s56 s57" d="M1126.2 516l18.1-18.1c8.2,-7.9 12,-16.4 11.6,-25.3l0-45.5"></path>
          </g>
        </g>
        <g class="ln4">
          <line class="s61 s62" x1="76" y1="528" x2="90" y2="582"></line>
          <line class="s62 s63" x1="90" y1="582" x2="90" y2="619"></line>
          <line class="s63 s64" x1="90" y1="618" x2="90" y2="657"></line>
          <line class="s64 s65" x1="90" y1="657" x2="90" y2="695"></line>
          <line class="s67 s66" x1="208" y1="809" x2="260" y2="809"></line>
          <line class="s70 s71" x1="405" y1="728" x2="502" y2="728"></line>
          <line class="s72 s73" x1="546" y1="741" x2="601" y2="796"></line>
          <line class="s69 s70" x1="270" y1="728" x2="405" y2="728"></line>
          <line class="s68 s69" x1="270" y1="728" x2="201" y2="728"></line>
          <g fill="none">
            <path class="s65 s66" d="M90.2 695l-0.1 90.9c-0.7,15.8 9.5,23.6 30.7,23.3l87.3-0.3"></path>
            <path class="s67 s70" d="M259.7 808.9l10.6 0c8.5,-0.4 15.7,-3.2 21.8,-8.3l63.6-63.6c6.5,-6 14.4,-9 23.7,-8.9l25.8 0"></path>
            <path class="s71 s72" d="M502.2 728.1l16.7 0c9.6,-0.2 17.7,3.1 24.2,10.1l3.1 3.1"></path>
          </g>
        </g>
        <g class="ln6">
          <line class="s108 s109" x1="841" y1="118" x2="841" y2="72"></line>
          <line class="s107 s108" x1="841" y1="163" x2="841" y2="118"></line>
          <line class="s106 s107" x1="841" y1="213" x2="841" y2="163"></line>
          <line class="s105 s106" x1="841" y1="311" x2="841" y2="213"></line>
          <line class="s104 s105" x1="841" y1="346" x2="841" y2="311"></line>
          <line class="s103 s104" x1="841" y1="346" x2="841" y2="379"></line>
          <line class="s95 s96" x1="481" y1="1043" x2="481" y2="1115"></line>
          <line class="s94 s95" x1="481" y1="1115" x2="481" y2="1197"></line>
          <line class="s93 s94" x1="481" y1="1197" x2="481" y2="1231"></line>
          <line class="s92 s93" x1="481" y1="1231" x2="481" y2="1265"></line>
          <line class="s91 s92" x1="481" y1="1265" x2="481" y2="1298"></line>
          <line class="s90 s91" x1="481" y1="1298" x2="481" y2="1332"></line>
          <line class="s89 s90" x1="481" y1="1332" x2="481" y2="1367"></line>
          <line class="s88 s89" x1="481" y1="1367" x2="481" y2="1401"></line>
          <line class="s87 s88" x1="481" y1="1401" x2="481" y2="1434"></line>
          <g fill="none">
            <path class="s102 s103" d="M841.1 379l0 2.1c-0.1,7.9-2.6,14.4-7.6,19.5l-27.3 27.3"></path>
            <path class="s101 s102" d="M806.2 427.9l-33.8 33.8c-7.6,8.2-11.1,15.6-10.5,22.2l0 13.4"></path>
            <path class="s100 s101" d="M761.9 497.4l0 29.6c-0,8.4 2.5,15 7.7,19.9l47.7 47.7"></path>
            <path class="s99 s100" d="M817.3 594.6l14.2 14.2c6.8,6.8 10,14.6 9.8,23.6l0 54.3"></path>
            <path class="s98 s99" d="M841.3 686.7l0 123.3c0.3,8.4-2.5,15.4-8.3,20.8l-40.6 40.6"></path>
            <path class="s97 s98" d="M792.5 871.4l-29 29c-8.2,6.4-18.8,9.5-31.8,9.2l-136.1 0c-9.8,0-18.1,3.7-24.9,11l-23.7 23.7"></path>
            <path class="s96 s97" d="M547 944.3l-55.6 55.6c-7.1,6.5-10.5,14-10.1,22.5l0 20.6"></path>
            <path class="s86 s87" d="M481.2 1434.4l0 4.4c0.6,8.5 6.9,18 18.7,28.6"></path>
          </g>
        </g>
        <g class="ln7">
          <line class="s128 s127" x1="212" y1="175" x2="212" y2="212"></line>
          <line class="s127 s126" x1="212" y1="212" x2="212" y2="251"></line>
          <line class="s126 s195" x1="212" y1="251" x2="212" y2="290"></line>
          <line class="s195 s125" x1="212" y1="290" x2="212" y2="328"></line>
          <line class="s125 s124" x1="212" y1="328" x2="212" y2="471"></line>
          <line class="s123 s122" x1="289" y1="563" x2="320" y2="594"></line>
          <line class="s122 s121" x1="320" y1="594" x2="344" y2="618"></line>
          <line class="s120 s119" x1="457" y1="639" x2="605" y2="639"></line>
          <line class="s119 s118" x1="605" y1="639" x2="769" y2="639"></line>
          <line class="s117 s116" x1="861" y1="679" x2="974" y2="791"></line>
          <line class="s116 s115" x1="974" y1="791" x2="1106" y2="924"></line>
          <line class="s114 s113" x1="1156" y1="1011" x2="1156" y2="1069"></line>
          <line class="s113 s112" x1="1156" y1="1069" x2="1156" y2="1117"></line>
          <line class="s112 s111" x1="1156" y1="1117" x2="1156" y2="1163"></line>
          <line class="s111 s110" x1="1156" y1="1163" x2="1156" y2="1222"></line>
          <line class="s110 s190" x1="1156" y1="1222" x2="1156" y2="1269"></line>
          <line class="s190 s189" x1="1156" y1="1269" x2="1156" y2="1327"></line>
          <line class="s189 s197" x1="1156" y1="1327" x2="1156" y2="1376"></line>
          <g fill="none">
            <path class="s124 s123" d="M211.7 470.8l0 6c0.1,6 2.9,11.8 8.5,17.3l68.3 68.3"></path>
            <path class="s121 s120" d="M344.2 618.1l12.6 12.6c5.6,5.4 12.9,8.1 22,8.2l77.7 0"></path>
            <path class="s118 s117" d="M769 638.9l39.7 0c8.2,-0.6 15.8,2.7 22.8,10l29.7 29.7"></path>
            <path class="s115 s114" d="M1106.4 923.7l40.9 40.9c6.2,5.6 9.1,13.8 8.7,24.7l0 21.3"></path>
          </g>
        </g>
        <g class="ln8">
          <line class="s129 s130" x1="1250" y1="598" x2="1279" y2="570"></line>
          <line class="s130 s131" x1="1223" y1="625" x2="1250" y2="598"></line>
          <line class="s131 s132" x1="1164" y1="684" x2="1223" y2="625"></line>
          <line class="s132 s133" x1="1164" y1="684" x2="1132" y2="717"></line>
          <line class="s133 s134" x1="1132" y1="717" x2="1080" y2="768"></line>
          <line class="s240 s241" x1="147" y1="935" x2="147" y2="964"></line>
          <line class="s241 s242" x1="147" y1="964" x2="147" y2="1002"></line>
          <line class="s243 s244" x1="147" y1="1000" x2="147" y2="1352"></line>
          <g fill="none">
            <path class="s134 s135" d="M1080 768.2l-39.3 39.3c-5.8,5.9-15.7,8.7-29.8,8.4l-26.4 0"></path>
            <path class="s135 s136" d="M984.5 815.9l-91.6 0c-9.5,-0.4-17.4,2.7-23.6,9.1l-61.4 61.4"></path>
            <path class="s193 s194" d="M280.5 753.2l-1.7 5.6c-2.4,6.6-5.5,11.6-9.2,15.2l-96.2 95.6"></path>
            <path class="s194 s240" d="M173.4 869.7l-15.5 15.5c-7.8,8.7-11.4,16.1-10.9,22.2l0 27.2"></path>
          </g>
        </g>
        <g class="ln9">
          <line class="s161 s160" x1="605" y1="72" x2="605" y2="116"></line>
          <line class="s160 s159" x1="605" y1="116" x2="605" y2="160"></line>
          <line class="s159 s158" x1="605" y1="160" x2="605" y2="203"></line>
          <line class="s156 s155" x1="526" y1="330" x2="526" y2="367"></line>
          <line class="s154 s153" x1="547" y1="413" x2="577" y2="443"></line>
          <line class="s153 s152" x1="577" y1="443" x2="664" y2="531"></line>
          <line class="s149 s148" x1="650" y1="943" x2="650" y2="981"></line>
          <line class="s148 s147" x1="650" y1="981" x2="650" y2="1090"></line>
          <line class="s147 s146" x1="650" y1="1090" x2="650" y2="1216"></line>
          <line class="s146 s145" x1="650" y1="1216" x2="650" y2="1261"></line>
          <line class="s145 s144" x1="650" y1="1261" x2="650" y2="1306"></line>
          <line class="s144 s143" x1="650" y1="1305" x2="650" y2="1358"></line>
          <line class="s143 s142" x1="650" y1="1358" x2="650" y2="1388"></line>
          <line class="s142 s141" x1="650" y1="1388" x2="650" y2="1424"></line>
          <line class="s141 s140" x1="650" y1="1424" x2="650" y2="1459"></line>
          <line class="s140 s139" x1="650" y1="1459" x2="650" y2="1494"></line>
          <line class="s139 s138" x1="650" y1="1495" x2="650" y2="1531"></line>
          <line class="s138 s137" x1="650" y1="1531" x2="650" y2="1560"></line>
          <g fill="none">
            <path class="s158 s157" d="M605 202.8l0 6.8c-0.5,9.1-4,16.7-10.7,22.9l-16.5 16.5"></path>
            <path class="s157 s156" d="M577.8 248.9l-41.8 41.8c-6.8,7.2-10,14.3-9.8,21.2l0 18.1"></path>
            <path class="s155 s154" d="M526.3 366.6l0.1 10.6c-0.2,10.6 2.4,18.5 8,23.7l12.3 12.3"></path>
            <path class="s152 s151" d="M663.9 530.5l4.1 4.1c4.1,4.8 6,10.1 5.7,15.8l0 43.5c0.2,6.9-2.3,12.9-7.5,17.9l-49.2 49.2"></path>
            <path class="s151 s150" d="M617 661l-37.2 37.2c-7.7,8.1-11.4,17.5-11.3,28.2l0 61.5c-0,10.1 2.7,17.6 8.3,22.4"></path>
            <path class="s150 s149" d="M576.8 810.3l64.9 64.9c5.8,6.3 8.6,13.7 8.4,22.1l0 45.7"></path>
          </g>
        </g>
        <g class="ln10">
          <line class="s232 s178" x1="525" y1="75" x2="525" y2="220"></line>
          <line class="s232 s178" x1="525" y1="220" x2="547" y2="255"></line>
          <line class="s232 s178" x1="547" y1="255" x2="586" y2="264"></line>
          <line class="s232 s178" x1="684" y1="349" x2="684" y2="373"></line>
          <line class="s177 s178" x1="684" y1="373" x2="684" y2="413"></line>
          <line class="s177 s176" x1="684" y1="413" x2="684" y2="522"></line>
          <line class="s174 s173" x1="948" y1="659" x2="1058" y2="768"></line>
          <line class="s171 s170" x1="987" y1="1070" x2="987" y2="1132"></line>
          <line class="s170 s169" x1="987" y1="1132" x2="987" y2="1176"></line>
          <line class="s169 s168" x1="987" y1="1176" x2="987" y2="1219"></line>
          <line class="s168 s167" x1="987" y1="1219" x2="987" y2="1263"></line>
          <line class="s167 s166" x1="987" y1="1263" x2="987" y2="1306"></line>
          <line class="s166 s165" x1="987" y1="1306" x2="987" y2="1350"></line>
          <line class="s165 s164" x1="987" y1="1350" x2="987" y2="1393"></line>
          <line class="s164 s163" x1="987" y1="1393" x2="987" y2="1437"></line>
          <line class="s163 s162" x1="987" y1="1437" x2="987" y2="1489"></line>
          <g fill="none">
            <path class="s234 s233" d="M593 264.1l32.5 0c7.2,-0 14.2,3.6 20.9,10.8l20.9 20.9"></path>
            <path class="s233 s232" d="M667.3 295.8l9.2 9.2c5.2,4.7 7.6,12.2 7.3,22.6l0 21.8"></path>
            <path class="s176 s175" d="M683.8 522.5l0 6.3c-0.3,6.9 2.1,12.6 7.2,17.1l18.4 18.4c6.2,5.6 13.1,8.3 20.6,8.1l99.3 0"></path>
            <path class="s175 s174" d="M829.3 572.3l20.2 0c8.2,-0.5 15.4,2.6 21.7,9.4l76.8 76.8"></path>
            <path class="s173 s172" d="M1057.7 768.2l20.9 20.9c5.4,4.6 7.9,10.7 7.5,18.4l0 124.2"></path>
            <path class="s172 s171" d="M986.9 1070.4c-0.6,-10.8 2.6,-20.5 9.4,-29.3l79.5-79.5c7.4,-6.9 10.8,-14 10.3,-21.2l0-8.7"></path>
          </g>
        </g>
        <g class="ln12">
          <line class="s192 s191" x1="500" y1="1489" x2="557" y2="1489"></line>
          <line class="s184 s183" x1="554" y1="1593" x2="450" y2="1593"></line>
          <line class="s185 s184" x1="450" y1="1593" x2="348" y2="1593"></line>
          <line class="s186 s185" x1="348" y1="1593" x2="246" y2="1593"></line>
          <g fill="none">
            <path class="s182 s192" d="M628.2 1559.5l0-48.6c0.2,-14.9-7,-22.1-21.6,-21.6l-49.3 0"></path>
            <path class="s183 s182" d="M554.1 1592.8l54.6 0c13.3,0.7 19.8,-5.8 19.4,-19.4l0-13.9"></path>
          </g>
        </g>
        <g class="ln11">
          <line class="s179 s180" x1="635" y1="1343" x2="731" y2="1343"></line>
          <path fill="none" class="s180 s181" d="M731.3 1342.8l62.1 0c10.9,0.4 18.8,-2.2 23.8,-7.8l16.9-16.9"></path>
        </g>

        <g class="ln13">
          <line class="s129 s130" x1="1190" y1="830" x2="1280" y2="830" style="stroke: #cd0505;"></line>
          <line class="s129 s130" x1="1280" y1="830" x2="1280" y2="1100" style="stroke: #cd0505;"></line>
        </g>
      </g>
      <g fill="none">
        <g class="p2p2" stroke-width="8">
          <path class="p69 p193" stroke="#82bc6b" d="M272.6 728.4c7,1.2 12,7.3 12,14.3 0,3-0.9,5.9-2.6,8.4"></path>
          <path class="p193 p235 uc" stroke="#83da6d" d="M278.5 754.6c-2.4,1.7-5.4,2.6-8.4,2.6-3,0-5.9,-0.9-8.3,-2.6"></path>
          <path class="p235 p69 uc" stroke="#06b9d5" d="M258.2 751c-1.7,-2.4-2.6,-5.4-2.6,-8.4 0,-7.1 5.1,-13.1 12,-14.3"></path>
          <path class="p28 p119" stroke="#4e374d" d="M591.3 658.3c-0.6,-1.6-0.9,-3.2-0.9,-4.9 0,-7 5,-13 11.9,-14.2"></path>
          <path class="p151 p28" stroke="#568a6a" d="M615.7 662.8c-2.7,3.1-6.7,5-10.9,5-4.2,0-8.1,-1.8-10.9,-5"></path>
          <path class="p151 p119" stroke="#9a5297" d="M607.3 639.2c6.9,1.2 11.9,7.2 11.9,14.2 0,1.9-0.4,3.8-1.1,5.6"></path>
          <path class="p175 p8" stroke="#ac6920" d="M832 572.4c7,1.1 12.2,7.2 12.2,14.3 0,2-0.4,4-1.3,5.9"></path>
          <path class="p8 p100" stroke="#e64203" d="M840.8 596.1c-2.8,3.3-6.8,5.2-11.1,5.2-4.3,0-8.4,-1.9-11.1,-5.2"></path>
          <path class="p100 p175" stroke="#c5a61d" d="M816.4 592.7c-0.8,-1.9-1.3,-3.9-1.3,-5.9 0,-7 5.1,-13.1 12,-14.3"></path>
          <path class="p53 p174" stroke="#497b61" d="M971.4 670.4c-2.5,1.7-5.5,2.7-8.5,2.7-7.2,0-13.3,-5.2-14.6,-12.2"></path>
          <path class="p80 p53" stroke="#431444" d="M975 649.8c1.7,2.5 2.7,5.5 2.7,8.5 0,3-0.9,6-2.7,8.5"></path>
          <path class="p174 p80" stroke="#85671d" d="M948.3 655.7c1.2,-7.1 7.4,-12.2 14.6,-12.2 3,0 6,0.9 8.5,2.7"></path>
          <path class="p79 p116" stroke="#88003d" d="M959.9 803.2c1,-5.9 5.5,-10.5 11.4,-11.8"></path>
          <path class="p116 p135" stroke="#c86e3f" d="M976.4 791.2c7.2,1 12.6,7.2 12.6,14.5 0,3.1-1,6.1-2.8,8.6"></path>
          <path class="p135 p79" stroke="#bf6e01" d="M982.7 817.7c-2.5,1.7-5.4,2.6-8.4,2.6-7.2,0-13.3,-5.2-14.5,-12.4"></path>
          <path class="p49 p70" stroke="#0662ae" d="M401.3 764.7c-4.8,-4.3-7.5,-10.4-7.5,-16.8 0,-6.3 2.6,-12.3 7.2,-16.6"></path>
          <path class="p74 p49" stroke="#431444" d="M438.4 753.4c-2.5,10.1-11.6,17.2-22,17.2-2.2,0-4.5,-0.3-6.6,-1"></path>
          <path class="p74 p70" stroke="#424e69" d="M409.5 726.4c2.3,-0.7 4.6,-1.1 7,-1.1 11,0 20.4,7.9 22.3,18.7"></path>
          <path class="p150 p51" stroke="#54669e" d="M574.9 807.1c-1.5,-3.5-2.3,-7.4-2.3,-11.2 0,-14.3 10.5,-26.4 24.7,-28.4"></path>
          <path class="p11 p150" stroke="#b7555d" d="M623.8 813.6c-5.4,6.9-13.8,11-22.6,11-8.8,0-17.2,-4.1-22.6,-11"></path>
          <path class="p51 p11" stroke="#6a1647" d="M604.7 767.5c14.4,1.8 25.2,14 25.2,28.5 0,3.7-0.7,7.3-2.1,10.7"></path>
          <path class="p73 p11" stroke="#69506c" d="M622.4 810c-7.5,-0.9-14.4,-4.8-19.1,-10.8"></path>
          <path class="p51 p73" stroke="#0662ae" d="M599.5 792.8c-1.5,-3.5-2.3,-7.4-2.3,-11.2 0,-3.8 0.8,-7.7 2.3,-11.2"></path>
          <path class="p30 p136" stroke="#84a611" d="M820.9 864.4c1.1,2.1 1.6,4.5 1.6,6.8 0,6.8-4.6,12.8-11.2,14.6"></path>
          <path class="p136 p98" stroke="#ffae01" d="M804.1 885.9c-5.2,-1.2-9.4,-5.1-11,-10.3"></path>
          <path class="p98 p30" stroke="#847710" d="M792.9 867.4c1.8,-6.6 7.7,-11.2 14.6,-11.2 2.5,0 4.9,0.6 7.1,1.8"></path>
          <line class="p182 p137" stroke="#aabfcd" x1="632.1" y1="1559.5" x2="645.8" y2="1559.5"></line>
          <line class="p32 p215" stroke="#806759" x1="820.1" y1="1142.1" x2="843.3" y2="1173.9"></line>
          <line class="p15 p210" stroke="#e2324c" x1="314.4" y1="1065.6" x2="336.8" y2="1032.2"></line>
          <line class="p1 p230" stroke="#e2324c" x1="978.9" y1="291.3" x2="997.1" y2="273.3"></line>
          <line class="p153 p83" stroke="#90525a" x1="576.9" y1="446.2" x2="577" y2="462.8"></line>
          <line class="p77 p148" stroke="#90525a" x1="636.8" y1="967.5" x2="648" y2="978.7"></line>
          <line class="p76 p97" stroke="#bf3f00" x1="546.9" y1="925.7" x2="547" y2="942.1"></line>
          <line class="p13 p75" stroke="#a60203" x1="485.3" y1="886.9" x2="491.6" y2="872.7"></line>
          <line class="p31 p78" stroke="#443710" x1="820" y1="961.7" x2="829.3" y2="948.1"></line>
          <line class="p115 p172" stroke="#8f675a" x1="1088.7" y1="930.7" x2="1103.8" y2="924.7"></line>
          <line class="p173 p134" stroke="#c5d51e" x1="1060.5" y1="768.2" x2="1077.3" y2="768.2"></line>
          <line class="p95 p211" stroke="#fb6f49" x1="479.3" y1="1117.4" x2="466.5" y2="1130.1"></line>
          <line class="p86 p191" stroke="#d8ac73" x1="499.9" y1="1470.3" x2="499.9" y2="1486.5"></line>
          <line class="p162 p39" stroke="#4b9e2d" x1="988.9" y1="1490.8" x2="1000.5" y2="1502.5"></line>
          <line class="p152 p176" stroke="#97b977" x1="666.5" y1="529.5" x2="681.2" y2="523.5"></line>
          <line class="p179 p143" stroke="#65abad" x1="636.6" y1="1344.8" x2="648" y2="1356.2"></line>
          <line class="p34 p181" stroke="#199063" x1="820.3" y1="1304.4" x2="832.1" y2="1316.2"></line>
          <line class="p207 p237 uc" stroke="#7f9bb5" x1="234.8" y1="572.8" x2="286.7" y2="584"></line>
          <line class="p123 p207" stroke="#c43087" x1="234.6" y1="572" x2="286.9" y2="562.3"></line>
          <line class="p124 p205" stroke="#c43087" x1="214.4" y1="471.3" x2="265.4" y2="479.9"></line>
          <line class="p22 p203" stroke="#806759" x1="347.8" y1="366" x2="368.1" y2="318.5"></line>
          <line class="p106 p227" stroke="#fb6f49" x1="843" y1="215.3" x2="865.1" y2="236.5"></line>
          <line class="p57 p223" stroke="#7f448e" x1="1154" y1="429.1" x2="1132.8" y2="452.1"></line>
          <line class="p132 p221" stroke="#fb9e4b" x1="1167" y1="684" x2="1195.7" y2="684"></line>
          <line class="p171 p216" stroke="#c19766" x1="965.1" y1="1120.8" x2="985.8" y2="1073"></line>
          <line class="p46 p61" stroke="#0662ae" x1="56.9" y1="527.8" x2="73.7" y2="527.8"></line>
          <line class="p48 p194" stroke="#838246" x1="173.6" y1="850.8" x2="173.6" y2="866.7"></line>
          <line class="p66 p231" stroke="#7e7eb3" x1="210.1" y1="806.9" x2="221.1" y2="795.5"></line>
          <line class="p68 p209" stroke="#7e7eb3" x1="203.3" y1="730" x2="214.3" y2="740.5"></line>
          <line class="p208 p236 uc" stroke="#7f9bb5" x1="217.8" y1="689.2" x2="236.2" y2="689.2"></line>
          <line class="p123 p237 uc" stroke="#4c6ba9" x1="288.7" y1="564.3" x2="288.6" y2="582.8"></line>
          <line class="p25 p239 uc" stroke="#08a37b" x1="413.5" y1="462.2" x2="413.5" y2="479.4"></line>
          <line class="p26 p84" stroke="#443710" x1="473.9" y1="545.2" x2="473.8" y2="562"></line>
          <line class="p85 p120" stroke="#88003d" x1="454.6" y1="640.8" x2="441.9" y2="653.5"></line>
          <line class="p10 p29" stroke="#6b3a13" x1="710.3" y1="730.1" x2="716.3" y2="744.8"></line>
          <line class="p29 p52" stroke="#084b54" x1="718.5" y1="749.9" x2="724.8" y2="764.7"></line>
          <line class="p99 p117" stroke="#c83f3d" x1="843.9" y1="685.7" x2="858.6" y2="679.6"></line>
          <line class="p118 p9" stroke="#af0240" x1="770" y1="641.5" x2="776.2" y2="657"></line>
          <line class="p6 p81" stroke="#a60203" x1="922" y1="517.6" x2="922" y2="533.8"></line>
          <line class="p102 p82" stroke="#bf3f00" x1="806.2" y1="430.4" x2="806.2" y2="447.5"></line>
          <line class="p2 p224" stroke="#e2324c" x1="1000.2" y1="333.1" x2="1011.6" y2="320.4"></line>
          <line class="p158 p228" stroke="#cc82a3" x1="607" y1="204.7" x2="619.5" y2="217.2"></line>
          <line class="p157 p234" stroke="#97b977" x1="579.8" y1="250.9" x2="591.1" y2="262.2"></line>
        </g>
        <g stroke="white" stroke-width="2">
          <circle cx="270" cy="742.7" r="14.6"></circle>
          <circle cx="605" cy="653.3" r="14.4"></circle>
          <circle cx="830" cy="586.8" r="14.5"></circle>
          <circle cx="963" cy="658.3" r="14.8"></circle>
          <circle cx="974" cy="805.7" r="14.7"></circle>
          <circle cx="416" cy="747.9" r="22.6"></circle>
          <path d="M626.4 810.2c-0.2,0-0.3,0-0.5,0-15.8,0-28.7,-12.8-28.7,-28.7 0,-5 1.3,-10 3.8,-14.3"></path>
          <circle cx="601" cy="795.9" r="28.7"></circle>
          <circle cx="807" cy="871.3" r="15.1"></circle>
          <line x1="628.4" y1="1559.6" x2="650.2" y2="1559.6"></line>
          <line x1="818.4" y1="1139.9" x2="845" y2="1176.1"></line>
          <line x1="312.8" y1="1067.9" x2="338.3" y2="1029.9"></line>
          <line x1="977.9" y1="292.5" x2="998.8" y2="271.5"></line>
          <line x1="576.9" y1="443.5" x2="577" y2="465.1"></line>
          <line x1="634.9" y1="965.5" x2="650" y2="980.7"></line>
          <line x1="547" y1="923" x2="547" y2="944.3"></line>
          <line x1="484.2" y1="889.5" x2="492.7" y2="870.2"></line>
          <line x1="818.4" y1="963.9" x2="830.9" y2="945.8"></line>
          <line x1="1086.1" y1="931.7" x2="1106.4" y2="923.7"></line>
          <line x1="1057.7" y1="768.2" x2="1080" y2="768.2"></line>
          <line x1="481.2" y1="1115.4" x2="464.5" y2="1132.1"></line>
          <line x1="499.9" y1="1467.5" x2="499.9" y2="1489.3"></line>
          <line x1="986.9" y1="1488.9" x2="1002.5" y2="1504.4"></line>
          <line x1="663.9" y1="530.5" x2="683.8" y2="522.5"></line>
          <line x1="634.6" y1="1342.8" x2="650" y2="1358.2"></line>
          <line x1="818.4" y1="1302.5" x2="834" y2="1318.1"></line>
          <line x1="232.7" y1="572.3" x2="288.8" y2="561.9"></line>
          <line x1="232.7" y1="572.3" x2="288.8" y2="584.4"></line>
          <line x1="211.7" y1="470.8" x2="268.4" y2="480.4"></line>
          <line x1="346.9" y1="368.2" x2="369.2" y2="316"></line>
          <line x1="841.1" y1="213.4" x2="867.1" y2="238.5"></line>
          <line x1="1155.9" y1="427.1" x2="1130.9" y2="454.1"></line>
          <line x1="1164.2" y1="684" x2="1198.5" y2="684"></line>
          <line x1="964" y1="1123.4" x2="986.9" y2="1070.4"></line>
          <line x1="54.5" y1="527.8" x2="76.6" y2="527.8"></line>
          <line x1="173.6" y1="869.5" x2="173.6" y2="848"></line>
          <line x1="208.2" y1="808.9" x2="223" y2="793.5"></line>
          <line x1="201.3" y1="728.1" x2="216.3" y2="742.4"></line>
          <line x1="215" y1="689.2" x2="239" y2="689.2"></line>
          <line x1="288.8" y1="562.2" x2="288.8" y2="585"></line>
          <line x1="413.5" y1="459.4" x2="413.5" y2="482.1"></line>
          <line x1="473.9" y1="542.5" x2="473.8" y2="564.8"></line>
          <line x1="456.5" y1="638.9" x2="439.9" y2="655.4"></line>
          <line x1="709.3" y1="727.5" x2="717.4" y2="747.4"></line>
          <line x1="717.4" y1="747.4" x2="725.9" y2="767.3"></line>
          <line x1="841.3" y1="686.7" x2="861.2" y2="678.6"></line>
          <line x1="769" y1="638.9" x2="777.2" y2="659.6"></line>
          <line x1="922" y1="514.8" x2="922" y2="536.5"></line>
          <line x1="806.2" y1="427.9" x2="806.2" y2="450.3"></line>
          <line x1="998.6" y1="334.8" x2="1013.2" y2="318.7"></line>
          <line x1="605" y1="202.8" x2="621.4" y2="219.1"></line>
          <line x1="577.8" y1="248.9" x2="593" y2="264.1"></line>
        </g>
      </g>
      <g class="stations" stroke-width="3" fill="white">
        <g class="ln5">
          <circle id="s84" r="5" cx="473.8" cy="564.8" class=""></circle>
          <circle id="s83" r="5" cx="577" cy="465.1" class=""></circle>
          <circle id="s85" r="5" cx="439.9" cy="655.4" class=""></circle>
          <circle id="s74" r="5" cx="439.4" cy="748.4" class=""></circle>
          <circle id="s75" r="5" cx="492.7" cy="870.2" class=""></circle>
          <circle id="s76" r="5" cx="547" cy="923" class=""></circle>
          <circle id="s77" r="5" cx="634.9" cy="965.5" class=""></circle>
          <circle id="s78" r="5" cx="830.9" cy="945.8" class=""></circle>
          <circle id="s79" r="5" cx="959.6" cy="805.4" class=""></circle>
          <circle id="s80" r="5" cx="973.5" cy="647.5" class=""></circle>
          <circle id="s81" r="5" cx="922" cy="536.6" class=""></circle>
          <circle id="s82" r="5" cx="806.3" cy="450.3" class=""></circle>
        </g>
        <g class="ln14">
          <circle id="s228" r="5" cx="621.4" cy="219.1"></circle>
          <circle id="s226" r="5" cx="907" cy="254.2"></circle>
          <circle id="s225" r="5" cx="939.4" cy="270.1"></circle>
          <circle id="s230" r="5" cx="977.4" cy="292.7" style="cursor: pointer;"></circle>
          <circle id="s224" r="5" cx="1013.2" cy="318.7"></circle>
          <circle id="s222" r="5" cx="1185.5" cy="589.8"></circle>
          <circle id="s220" r="5" cx="1196.6" cy="750.9"></circle>
          <circle id="s210" r="5" cx="338.3" cy="1029.9"></circle>
          <circle id="s219" r="5" cx="1182.8" cy="828.7"></circle>
          <circle id="s218" r="5" cx="1168.3" cy="874.5"></circle>
          <circle id="s217" r="5" cx="1046.8" cy="1059.6"></circle>
          <circle id="s214" r="5" cx="783.8" cy="1189.8"></circle>
          <circle id="s213" r="5" cx="694" cy="1195.7"></circle>
          <circle id="s212" r="5" cx="550.7" cy="1170.5"></circle>
          <circle id="s211" r="5" cx="464.5" cy="1132.1"></circle>
          <circle id="s231" r="5" cx="223" cy="793.5"></circle>
          <circle id="s209" r="5" cx="216.3" cy="742.4"></circle>
          <circle id="s208" r="5" cx="215" cy="689.2"></circle>
          <circle id="s207" r="5" cx="232.7" cy="572.3"></circle>
          <circle id="s206" r="5" cx="241.5" cy="543.6"></circle>
          <circle id="s205" r="5" cx="268.4" cy="480.4"></circle>
          <circle id="s203" r="5" cx="346.9" cy="368.2"></circle>
          <circle id="s215" r="5" cx="845" cy="1176.1"></circle>
          <circle id="s216" r="5" cx="964" cy="1123.4"></circle>
          <circle id="s221" r="5" cx="1198.5" cy="684"></circle>
          <circle id="s204" r="5" cx="316.5" cy="404.2"></circle>
          <circle id="s202" r="5" cx="435" cy="293.5"></circle>
          <circle id="s201" r="5" cx="496.4" cy="258.8"></circle>
          <circle id="s229" r="5" cx="558.9" cy="234.5"></circle>
          <circle id="s227" r="5" cx="867.1" cy="238.5"></circle>
          <circle id="s223" r="5" cx="1130.9" cy="454"></circle>
        </g>
        <g class="ln1">

          <circle id="s2" r="5" cx="998.6" cy="334.8"></circle>
          <circle id="s3" r="5" cx="998.6" cy="376.4"></circle>
          <circle id="s4" r="5" cx="998.6" cy="415.3"></circle>
          <circle id="s5" r="5" cx="972.4" cy="464.4"></circle>
          <circle id="s6" r="5" cx="922" cy="514.8" class=""></circle>
          <circle id="s7" r="5" cx="879.7" cy="557.1"></circle>
          <circle id="s8" r="5" cx="842.2" cy="594.6"></circle>
          <circle id="s9" r="5" cx="777.2" cy="659.6"></circle>
          <circle id="s10" r="5" cx="709.3" cy="727.5"></circle>
          <circle id="s11" r="5" cx="626.5" cy="810.3"></circle>
          <circle id="s12" r="5" cx="565.4" cy="871.4"></circle>
          <circle id="s13" r="5" cx="484.2" cy="889.5" class=""></circle>
          <circle id="s14" r="5" cx="357.9" cy="928.4"></circle>
          <circle id="s15" r="5" cx="312.8" cy="1067.9"></circle>
          <circle id="s16" r="5" cx="312.8" cy="1102.2"></circle>
          <circle id="s17" r="5" cx="312.8" cy="1156.3"></circle>
          <circle id="s18" r="5" cx="312.8" cy="1197.2"></circle>
          <circle id="s19" r="5" cx="312.8" cy="1250.9"></circle>
          <circle id="s196" r="5" cx="312.8" cy="1291.9"></circle>
          <circle id="s199" r="5" cx="312.8" cy="1333.7"></circle>
          <circle id="s200" r="5" cx="312.8" cy="1374.5"></circle>
          <circle id="s252" r="5" cx="312.8" cy="1415.3"></circle>
          <circle id="s253" r="5" cx="312.8" cy="1456.1"></circle>
          <circle id="s254" r="5" cx="280" cy="1489.8"></circle>
          <circle id="s255" r="5" cx="147" cy="1489.8"></circle>
          <circle id="s1" r="5" cx="998.9" cy="271.8"></circle>
        </g>
        <g class="ln2">
          <circle id="s265" r="5" cx="369.2" cy="105.4"></circle>
          <circle id="s264" r="5" cx="369.2" cy="140"></circle>

          <circle id="s20" r="5" cx="369.2" cy="174.6"></circle>
          <circle id="s21" r="5" cx="369.2" cy="220.2"></circle>
          <circle id="s22" r="5" cx="369.2" cy="316"></circle>
          <circle id="s23" r="5" cx="369.2" cy="386.4"></circle>
          <circle id="s24" r="5" cx="369.2" cy="413.9"></circle>
          <circle id="s25" r="5" cx="413.5" cy="482.1"></circle>
          <circle id="s26" r="5" cx="473.9" cy="542.5" class=""></circle>
          <circle id="s27" r="5" cx="508.1" cy="576.7"></circle>
          <circle id="s28" r="5" cx="592.4" cy="661"></circle>
          <circle id="s29" r="5" cx="717.4" cy="747.4"></circle>
          <circle id="s30" r="5" cx="818.4" cy="860.9"></circle>
          <circle id="s31" r="5" cx="818.4" cy="963.9" class=""></circle>
          <circle id="s32" r="5" cx="818.4" cy="1139.9"></circle>
          <circle id="s198" r="5" cx="818.4" cy="1211.7"></circle>
          <circle id="s33" r="5" cx="818.4" cy="1251.1"></circle>
          <circle id="s34" r="5" cx="818.4" cy="1302.5"></circle>
          <circle id="s35" r="5" cx="818.4" cy="1348.4"></circle>
          <circle id="s36" r="5" cx="818.4" cy="1381"></circle>
          <circle id="s37" r="5" cx="818.4" cy="1421.3"></circle>
          <circle id="s38" r="5" cx="818.4" cy="1460.9"></circle>
          <circle id="s39" r="5" cx="1002.5" cy="1504.4"></circle>
          <circle id="s187" r="5" cx="1133.6" cy="1504.4"></circle>


        </g>
        <g class="ln3">
          <circle id="s42" r="5" cx="54.4" cy="387.1"></circle>
          <circle id="s41" r="5" cx="54.4" cy="351.4"></circle>
          <circle id="s40" r="5" cx="54.4" cy="316.3"></circle>
          <circle id="s188" r="5" cx="54.4" cy="269.4"></circle>
          <circle id="s43" r="5" cx="54.4" cy="422.6"></circle>
          <circle id="s44" r="5" cx="54.4" cy="457.8"></circle>
          <circle id="s45" r="5" cx="54.4" cy="493.1"></circle>
          <circle id="s47" r="5" cx="108.3" cy="848"></circle>
          <circle id="s50" r="5" cx="502.4" cy="767.3"></circle>
          <circle id="s54" r="5" cx="1031.7" cy="610.5"></circle>
          <circle id="s56" r="5" cx="1126.2" cy="516"></circle>
          <circle id="s58" r="5" cx="1155.9" cy="374.8"></circle>
          <circle id="s59" r="5" cx="1155.9" cy="321.7"></circle>
          <circle id="s60" r="5" cx="1155.9" cy="271.2"></circle>
          <circle id="s55" r="5" cx="1078.7" cy="563.6"></circle>
          <circle id="s51" r="5" cx="601.3" cy="767.3"></circle>
          <circle id="s49" r="5" cx="405.2" cy="767.3" class=""></circle>
          <circle id="s48" r="5" cx="173.6" cy="848"></circle>
          <circle id="s46" r="5" cx="54.4" cy="527.9"></circle>
          <circle id="s52" r="5" cx="725.9" cy="767.3"></circle>
          <circle id="s53" r="5" cx="973.3" cy="668.9" class=""></circle>
          <circle id="s57" r="5" cx="1155.9" cy="427.1"></circle>
        </g>
        <g class="ln4">
          <circle id="s61" r="5" cx="76.2" cy="528.1"></circle>
          <circle id="s62" r="5" cx="90.1" cy="582.1"></circle>
          <circle id="s63" r="5" cx="89.9" cy="619.2"></circle>
          <circle id="s64" r="5" cx="90" cy="656.8"></circle>
          <circle id="s65" r="5" cx="90" cy="694.7"></circle>
          <circle id="s66" r="5" cx="208.2" cy="808.9"></circle>
          <circle id="s67" r="5" cx="259.7" cy="808.9"></circle>
          <circle id="s70" r="5" cx="405.2" cy="728.1" class=""></circle>
          <circle id="s71" r="5" cx="502.2" cy="728.1"></circle>
          <circle id="s72" r="5" cx="546.3" cy="741.3"></circle>
          <circle id="s73" r="5" cx="601.1" cy="796.1"></circle>
          <circle id="s69" r="5" cx="270.5" cy="728.1"></circle>
          <circle id="s68" r="5" cx="201.3" cy="728.1"></circle>
        </g>
        <g class="ln6">
          <circle id="s109" r="5" cx="841.1" cy="72.5"></circle>
          <circle id="s108" r="5" cx="841.1" cy="117.9"></circle>
          <circle id="s107" r="5" cx="841.1" cy="163.4"></circle>
          <circle id="s106" r="5" cx="841.1" cy="213.3"></circle>
          <circle id="s105" r="5" cx="841.1" cy="311.1"></circle>
          <circle id="s104" r="5" cx="841.1" cy="345.9"></circle>
          <circle id="s103" r="5" cx="841.1" cy="379"></circle>
          <circle id="s102" r="5" cx="806.2" cy="427.9" class=""></circle>
          <circle id="s101" r="5" cx="761.9" cy="497.4"></circle>
          <circle id="s100" r="5" cx="817.3" cy="594.6"></circle>
          <circle id="s99" r="5" cx="841.3" cy="686.7"></circle>
          <circle id="s98" r="5" cx="792.5" cy="871.4"></circle>
          <circle id="s97" r="5" cx="547" cy="944.3" class=""></circle>
          <circle id="s96" r="5" cx="481.2" cy="1043"></circle>
          <circle id="s95" r="5" cx="481.2" cy="1115.4"></circle>
          <circle id="s94" r="5" cx="481.2" cy="1196.6"></circle>
          <circle id="s93" r="5" cx="481.2" cy="1230.7"></circle>
          <circle id="s92" r="5" cx="481.2" cy="1264.6"></circle>
          <circle id="s91" r="5" cx="481.2" cy="1298.3"></circle>
          <circle id="s90" r="5" cx="481.2" cy="1332.4"></circle>
          <circle id="s89" r="5" cx="481.2" cy="1366.6"></circle>
          <circle id="s88" r="5" cx="481.2" cy="1400.8"></circle>
          <circle id="s87" r="5" cx="481.2" cy="1434.4"></circle>
          <circle id="s86" r="5" cx="499.9" cy="1467.5"></circle>
        </g>
        <g class="ln7">
          <circle id="s128" r="5" cx="211.7" cy="174.8"></circle>
          <circle id="s127" r="5" cx="211.7" cy="212.4"></circle>
          <circle id="s126" r="5" cx="211.7" cy="250.9"></circle>
          <circle id="s195" r="5" cx="211.7" cy="289.5"></circle>
          <circle id="s125" r="5" cx="211.7" cy="327.7"></circle>
          <circle id="s124" r="5" cx="211.7" cy="470.8"></circle>
          <circle id="s123" r="5" cx="288.8" cy="562.2"></circle>
          <circle id="s122" r="5" cx="320.1" cy="593.8"></circle>
          <circle id="s120" r="5" cx="456.5" cy="638.9" class=""></circle>
          <circle id="s121" r="5" cx="344.2" cy="618.1"></circle>
          <circle id="s119" r="5" cx="605" cy="638.9"></circle>
          <circle id="s118" r="5" cx="769" cy="638.9"></circle>
          <circle id="s117" r="5" cx="861.2" cy="678.6"></circle>
          <circle id="s116" r="5" cx="973.8" cy="791.1" class=""></circle>
          <circle id="s115" r="5" cx="1106.4" cy="923.7"></circle>
          <circle id="s114" r="5" cx="1156" cy="1010.7"></circle>
          <circle id="s113" r="5" cx="1156" cy="1068.7"></circle>
          <circle id="s112" r="5" cx="1156" cy="1116.5"></circle>
          <circle id="s111" r="5" cx="1156" cy="1163.4"></circle>
          <circle id="s110" r="5" cx="1156" cy="1222.5"></circle>
          <circle id="s190" r="5" cx="1156" cy="1268.7"></circle>
          <circle id="s189" r="5" cx="1156" cy="1326.5"></circle>
          <circle id="s197" r="5" cx="1156" cy="1375.7"></circle>
        </g>
        <g class="ln8">
          <circle id="s129" r="5" cx="1278.8" cy="569.5"></circle>
          <circle id="s130" r="5" cx="1250.4" cy="597.9"></circle>
          <circle id="s131" r="5" cx="1223.1" cy="625.1"></circle>
          <circle id="s132" r="5" cx="1164.2" cy="684"></circle>
          <circle id="s133" r="5" cx="1131.7" cy="716.5"></circle>
          <circle id="s134" r="5" cx="1080" cy="768.2"></circle>
          <circle id="s135" r="5" cx="984.5" cy="815.9" class=""></circle>
          <circle id="s136" r="5" cx="808" cy="886.4"></circle>
          <circle id="s194" r="5" cx="173.6" cy="869.5"></circle>
          <circle id="s240" r="5" cx="147.2" cy="934.7"></circle>
          <circle id="s241" r="5" cx="147" cy="964.2"></circle>
          <circle id="s242" r="5" cx="147" cy="1002.2"></circle>
          <circle id="s193" r="5" cx="280.5" cy="753.2"></circle>
          <circle id="s243" r="5" cx="147" cy="1050"></circle>
          <circle id="s244" r="5" cx="147" cy="1100"></circle>
          <circle id="s245" r="5" cx="147" cy="1150"></circle>
          <circle id="s246" r="5" cx="147" cy="1200"></circle>
          <circle id="s247" r="5" cx="147" cy="1250"></circle>
          <circle id="s248" r="5" cx="147" cy="1300"></circle>
          <circle id="s249" r="5" cx="147" cy="1350"></circle>
        </g>
        <g class="ln9">
          <circle id="s161" r="5" cx="605" cy="72.4"></circle>
          <circle id="s160" r="5" cx="605" cy="115.9"></circle>
          <circle id="s159" r="5" cx="605" cy="159.6"></circle>
          <circle id="s158" r="5" cx="605" cy="202.8"></circle>
          <circle id="s157" r="5" cx="577.8" cy="248.9"></circle>
          <circle id="s156" r="5" cx="526.3" cy="330"></circle>
          <circle id="s155" r="5" cx="526.3" cy="366.7"></circle>
          <circle id="s154" r="5" cx="546.7" cy="413.3"></circle>
          <circle id="s153" r="5" cx="576.9" cy="443.5" class=""></circle>
          <circle id="s152" r="5" cx="663.9" cy="530.5"></circle>
          <circle id="s151" r="5" cx="617" cy="661"></circle>
          <circle id="s150" r="5" cx="576.8" cy="810.3"></circle>
          <circle id="s149" r="5" cx="650" cy="943"></circle>
          <circle id="s148" r="5" cx="650" cy="980.7" class=""></circle>
          <circle id="s147" r="5" cx="650" cy="1089.6"></circle>
          <circle id="s146" r="5" cx="650" cy="1216.4"></circle>
          <circle id="s145" r="5" cx="650" cy="1261.2"></circle>
          <circle id="s144" r="5" cx="650" cy="1306"></circle>
          <circle id="s143" r="5" cx="650" cy="1358.2"></circle>
          <circle id="s142" r="5" cx="650" cy="1387.8"></circle>
          <circle id="s141" r="5" cx="650" cy="1423.5"></circle>
          <circle id="s140" r="5" cx="650" cy="1459.2"></circle>
          <circle id="s139" r="5" cx="650" cy="1494.8"></circle>
          <circle id="s138" r="5" cx="650" cy="1530.5"></circle>
          <circle id="s137" r="5" cx="650" cy="1559.5"></circle>
        </g>
        <g class="ln10">
          <circle id="s251" r="5" cx="525" cy="74"></circle>
          <circle id="s250" r="5" cx="525" cy="140.8"></circle>
          <circle id="s233" r="5" cx="667.3" cy="295.8"></circle>
          <circle id="s232" r="5" cx="683.8" cy="349.3"></circle>
          <circle id="s178" r="5" cx="683.8" cy="373.2"></circle>
          <circle id="s177" r="5" cx="683.8" cy="412.7"></circle>
          <circle id="s176" r="5" cx="683.8" cy="522.5"></circle>
          <circle id="s175" r="5" cx="829.3" cy="572.3"></circle>
          <circle id="s174" r="5" cx="948.1" cy="658.6" class=""></circle>
          <circle id="s173" r="5" cx="1057.7" cy="768.2"></circle>
          <circle id="s172" r="5" cx="1086.1" cy="931.7"></circle>
          <circle id="s171" r="5" cx="986.9" cy="1070.4"></circle>
          <circle id="s170" r="5" cx="986.9" cy="1132.5"></circle>
          <circle id="s168" r="5" cx="986.9" cy="1218.8"></circle>
          <circle id="s167" r="5" cx="986.9" cy="1263.1"></circle>
          <circle id="s166" r="5" cx="986.9" cy="1306"></circle>
          <circle id="s165" r="5" cx="986.9" cy="1349.9"></circle>
          <circle id="s164" r="5" cx="986.9" cy="1393.5"></circle>
          <circle id="s163" r="5" cx="986.9" cy="1437.1"></circle>
          <circle id="s162" r="5" cx="986.9" cy="1488.9"></circle>
          <circle id="s169" r="5" cx="986.9" cy="1175.7"></circle>
          <circle id="s234" r="5" cx="593" cy="264.1"></circle>
        </g>
        <g class="ln12">
          <circle id="s191" r="5" cx="499.9" cy="1489.3"></circle>
          <circle id="s192" r="5" cx="557.2" cy="1489.3"></circle>
          <circle id="s182" r="5" cx="628.2" cy="1559.5"></circle>
          <circle id="s183" r="5" cx="554.1" cy="1592.8"></circle>
          <circle id="s184" r="5" cx="450.1" cy="1592.8"></circle>
          <circle id="s185" r="5" cx="348.3" cy="1592.8"></circle>
          <circle id="s186" r="5" cx="246" cy="1592.8"></circle>
        </g>
        <g class="ln13" style="stroke: #cd0505;">
          <circle id="s256" r="5" cx="1280" cy="860"></circle>
          <circle id="s257" r="5" cx="1280" cy="900"></circle>
          <circle id="s258" r="5" cx="1280" cy="940"></circle>
          <circle id="s259" r="5" cx="1280" cy="980"></circle>
          <circle id="s260" r="5" cx="1280" cy="1020"></circle>
          <circle id="s261" r="5" cx="1280" cy="1060"></circle>
          <circle id="s262" r="5" cx="1280" cy="1100"></circle>
        </g>
        <g class="ln15">
          <circle id="s239" r="5" cx="413.5" cy="459.4"></circle>
          <circle id="s236" r="5" cx="239" cy="689.2"></circle>
          <circle id="s235" r="5" cx="259.7" cy="753.2"></circle>
          <circle id="s238" r="5" cx="379.9" cy="493.1"></circle>
          <circle id="s237" r="5" cx="288.8" cy="584.6"></circle>
        </g>
        <g class="ln16" style="stroke: #7acdce">
          <circle id="s263" r="5" cx="1095" cy="680"></circle>
        </g>
        <g class="ln11">
          <circle id="s179" r="5" cx="634.6" cy="1342.8"></circle>
          <circle id="s180" r="5" cx="731.3" cy="1342.8"></circle>
          <circle id="s181" r="5" cx="834" cy="1318.1"></circle>
        </g>
      </g>
      <g style="opacity:0.5" fill="white">
        <rect x="882" y="600" width="27" height="11"></rect>
        <rect x="265" y="455" width="26" height="11"></rect>
        <rect x="827" y="846" width="33" height="12"></rect>
        <rect x="757" y="883" width="33" height="12"></rect>
        <rect x="995" y="825" width="35" height="12"></rect>
        <rect x="1138" y="914" width="24" height="12"></rect>
        <rect x="358" y="1062" width="33" height="12"></rect>
        <rect x="864" y="690" width="30" height="12"></rect>
        <rect x="1153" y="526" width="30" height="12"></rect>
        <rect x="1190" y="694" width="22" height="12"></rect>
        <rect x="1185" y="728" width="21" height="12"></rect>
        <rect x="261" y="594" width="34" height="12"></rect>
        <rect x="301" y="552" width="30" height="12"></rect>
        <rect x="559" y="726" width="21" height="12"></rect>
        <rect x="1057" y="370" width="33" height="12"></rect>
        <rect x="1019" y="331" width="36" height="12"></rect>
        <rect x="597" y="476" width="34" height="12"></rect>
        <rect x="259" y="764" width="27" height="11"></rect>
        <rect x="555" y="790" width="30" height="12"></rect>
        <rect x="384" y="741" width="21" height="12"></rect>
      </g>
      <g style="font-family: 'MMM', sans-serif;">
        <g text-anchor="end">
          <g class="st">
            <text class="s263" x="1133" y="670">Лефортово</text>
            <text class="s256" x="1375" y="864">Стахановская</text>
            <text class="s257" x="1335" y="904">Окская</text>
            <text class="s258" x="1385" y="944">Юго-Восточная</text>
            <text class="s259" x="1335" y="984">Косино</text>
            <text class="s260" x="1425" y="1024">Улица Дмитриевского</text>
            <text class="s261" x="1378" y="1064">Лухмановская</text>
            <text class="s262" x="1365" y="1104">Некрасовка</text>

            <text class="s251" x="515" y="78">Селигерская</text>
            <text class="s250" x="515" y="144">Верхние Лихоборы</text>
            <text class="s209" x="207" y="752">Деловой центр</text>
            <text class="s66 s231" x="210" y="796">Кутузовская</text>
            <text class="s211" x="466" y="1150">Площадь</text>
            <text class="s211" x="466" y="1162">Гагарина</text>
            <text class="s68" x="192" y="731">Международная</text>
            <text class="s191" x="503" y="1507">Битцевский парк</text>
            <text class="s182" x="619" y="1563">Улица Старокачаловская</text>
            <text class="s84 s26" x="466" y="557">Белорусская</text>
            <text class="s75 s13" x="478" y="876">Парк культуры</text>
            <text class="s76 s97" x="539" y="937">Октябрьская</text>
            <text class="s77" x="637" y="983">Добрынинская</text>
            <text class="s85" x="428" y="663">Краснопресненская</text>
            <text class="s223" x="1121" y="459">Измайлово</text>
            <text class="s204" x="302" y="405">Стрешнево</text>
            <text class="s206" x="235" y="537">Зорге</text>
            <text class="s207" x="227" y="566">Хорошево</text>
            <text class="s10" x="711" y="704">Охотный</text>
            <text class="s10" x="711" y="716">ряд</text>
            <text class="s28" x="582" y="668">Тверская</text>
            <text class="s49 s74 s70" x="426" y="751">Киевская</text>
            <text class="s100" x="809" y="606">Тургеневская</text>
            <text class="s98 s136" x="797" y="892">Третьяковская</text>
            <text class="s118" x="772" y="627">Кузнецкий Мост</text>
            <text class="s152" x="656" y="543">Цветной бульвар</text>
            <text class="s150" x="569" y="822">Боровицкая</text>
            <text class="s174" x="938" y="668">Чкаловская</text>
            <text class="s172" x="1074" y="932">Крестьянская</text>
            <text class="s172" x="1074" y="944">Застава</text>
            <text class="s175" x="856" y="548">Серетенский</text>
            <text class="s175" x="856" y="560">бульвар</text>
            <text class="s173" x="1054" y="783">Римская</text>
            <text class="s171 s216" x="958" y="1090">Дубровка</text>
            <text class="s179" x="634" y="1361">Каховская</text>
            <text class="s79 s116" x="955" y="795">Таганская</text>
            <text class="s225" x="935" y="285">Белокаменная</text>
            <text class="s229" x="561" y="224">Окружная</text>
            <text class="s202" x="440" y="282">Коптево</text>
            <text class="s73" x="586" y="799">Александровский сад</text>
            <text class="s203" x="334" y="370">Балтийская</text>
            <text class="s22" x="358" y="319">Войковская</text>
            <text class="s201" x="499" y="248">Лихоборы</text>
          </g>
        </g>
        <g text-anchor="start">
          <g class="st">
            <text class="s67" x="239" y="826">Студенческая</text>
            <text class="s39" x="984" y="1525">Красногвардейская</text>
            <text class="s30" x="826" y="856">Новокузнецкая</text>
            <text class="s52" x="726" y="786">Площадь</text>
            <text class="s52" x="726" y="799">Революции</text>
            <text class="s208 s236" x="251" y="693">Шелепиха</text>
            <text class="s193 s235" x="228" y="773">Деловой центр</text>
            <text class="s121" x="357" y="620">Улица 1905 года</text>
            <text class="s122" x="337" y="598">Беговая</text>
            <text class="s177" x="695" y="416">Достоевская</text>
            <text class="s129" x="1277" y="589">Новокосино</text>
            <text class="s130" x="1248" y="621">Новогиреево</text>
            <text class="s78 s31" x="831" y="966">Павелецкая</text>
            <text class="s81 s6" x="934" y="539">Комсомольская</text>
            <text class="s82 s102" x="814" y="443">Проспект Мира</text>
            <text class="s83" x="574" y="484">Новослободская</text>
            <text class="s226" x="916" y="250">Ростокино</text>
            <text class="s224" x="1025" y="319">Локомотив</text>
            <text class="s220" x="1206" y="755">Андроновка</text>
            <text class="s219" x="1193" y="820">Нижегородская</text>
            <text class="s218" x="1179" y="879">Новохохловская</text>
            <text class="s210" x="346" y="1023">Лужники</text>
            <text class="s205" x="276" y="491">Панфиловская</text>
            <text class="s212" x="554" y="1161">Крымская</text>
            <text class="s213" x="664" y="1183">Верхние Котлы</text>
            <text class="s214" x="773" y="1179">ЗИЛ</text>
            <text class="s217" x="1053" y="1076">Угрешская</text>
            <text class="s1 s230" x="1010" y="273">Бульвар</text>
            <text class="s1 s230" x="1010" y="286">Рокоссовского</text>
            <text class="s2" x="1009" y="340">Черкизовская</text>
            <text class="s3" x="1010" y="378">Преображенская</text>
            <text class="s3" x="1011" y="390">площадь</text>
            <text class="s4" x="1010" y="418">Сокольники</text>
            <text class="s5" x="981" y="478">Красносельская</text>
            <text class="s7" x="887" y="570">Красные</text>
            <text class="s7" x="887" y="582">Ворота</text>
            <text class="s8" x="850" y="608">Чистые пруды</text>
            <text class="s9" x="788" y="668">Лубянка</text>
            <text class="s11" x="636" y="825">Библиотека им. Ленина</text>
            <text class="s12" x="563" y="890">Кропоткинская</text>
            <text class="s14" x="366" y="943">Фрунзенская</text>
            <text class="s15" x="325" y="1071">Спортивная</text>
            <text class="s16" x="325" y="1105">Воробьевы</text>
            <text class="s16" x="325" y="1117">горы</text>
            <text class="s17" x="325" y="1159">Унивеситет</text>
            <text class="s18" x="325" y="1201">Проспект Вернадского</text>
            <text class="s19" x="325" y="1255">Юго-Западная</text>
            <text class="s196" x="325" y="1295">Тропарево</text>
            <text class="s199" x="325" y="1337">Румянцево</text>
            <text class="s200" x="325" y="1379">Саларьево</text>
            <text class="s252" x="325" y="1419.8">Филатов луг</text>
            <text class="s253" x="325" y="1460.6">Прокшино</text>
            <text class="s254" x="250" y="1510">Ольховая</text>
            <text class="s255" x="120" y="1510">Столбово</text>
            <text class="s264" x="270" y="144">Беломорская</text>
            <text class="s265" x="300" y="110.4">Ховрино</text>
            <text class="s20" x="381" y="179">Речной вокзал</text>
            <text class="s23" x="381" y="390">Сокол</text>
            <text class="s21" x="381" y="224">Водный стадион</text>
            <text class="s24" x="381" y="417">Аэропорт</text>
            <text class="s25" x="426" y="485">Динамо</text>
            <text class="s27" x="517" y="571">Маяковская</text>
            <text class="s29" x="722" y="741">Театральная</text>
            <text class="s32 s215" x="831" y="1143">Автозаводская</text>
            <text class="s198" x="829" y="1215">Технопарк</text>
            <text class="s33" x="829" y="1255">Коломенская</text>
            <text class="s34 s181" x="829" y="1306">Каширская</text>
            <text class="s35" x="829" y="1353">Кантемировская</text>
            <text class="s36" x="829" y="1385">Царицыно</text>
            <text class="s37" x="829" y="1426">Орехово</text>
            <text class="s38" x="829" y="1465">Домодедовская</text>
            <text class="s187" x="1119" y="1526">Алма-Атинская</text>
            <text class="s40" x="66" y="320">Митино</text>
            <text class="s41" x="66" y="356">Волоколамская</text>
            <text class="s42" x="66" y="391">Мякинино</text>
            <text class="s43" x="66" y="427">Строгино</text>
            <text class="s44" x="66" y="462">Крылатское</text>
            <text class="s45" x="66" y="497">Молодёжная</text>
            <text class="s50 s71" x="474" y="756">Смоленская</text>
            <text class="s51 s72" x="597" y="757">Арбатская</text>
            <text class="s53 s80" x="987" y="683">Курская</text>
            <text class="s54" x="1031" y="630">Бауманская</text>
            <text class="s55" x="1077" y="583">Электрозаводская</text>
            <text class="s56" x="1123" y="535">Семеновская</text>
            <text class="s57" x="1168" y="431">Партизанская</text>
            <text class="s58" x="1168" y="379">Измайловская</text>
            <text class="s59" x="1168" y="326">Первомайская</text>
            <text class="s60" x="1168" y="273">Щелковская</text>
            <text class="s51 s72" x="554" y="735">Арбатская</text>
            <text class="s69" x="252" y="718">Выставочная</text>
            <text class="s50 s71" x="476" y="718">Смоленская</text>
            <text class="s109" x="853" y="76">Медведково</text>
            <text class="s108" x="853" y="122">Бабушкинская</text>
            <text class="s107" x="853" y="167">Свиблово</text>
            <text class="s103" x="852" y="383">Рижская</text>
            <text class="s99 s117" x="851" y="699">Китай-город</text>
            <text class="s96" x="492" y="1046">Шаболовская</text>
            <text class="s94" x="492" y="1199">Академическая</text>
            <text class="s93" x="492" y="1234">Профсоюзная</text>
            <text class="s92" x="492" y="1267">Новые Черемушки</text>
            <text class="s91" x="492" y="1301">Калужская</text>
            <text class="s90" x="492" y="1335">Беляево</text>
            <text class="s89" x="492" y="1369">Коньково</text>
            <text class="s88" x="492" y="1404">Тёплый Стан</text>
            <text class="s87" x="492" y="1437">Ясенево</text>
            <text class="s86" x="511" y="1471">Новоясеневская</text>
            <text class="s106 s227" x="859" y="219">Ботанический сад</text>
            <text class="s101" x="772" y="502">Сухаревская</text>
            <text class="s128" x="223" y="179">Планерная</text>
            <text class="s127" x="223" y="217">Сходненская</text>
            <text class="s126" x="223" y="255">Тушинская</text>
            <text class="s195" x="223" y="293">Спартак</text>
            <text class="s125" x="223" y="333">Щукинская</text>
            <text class="s120" x="463" y="628">Баррикадная</text>
            <text class="s119" x="575" y="627">Пушкинская</text>
            <text class="s115" x="1118" y="923">Пролетарская</text>
            <text class="s114" x="1167" y="1015">Волгоградский</text>
            <text class="s114" x="1167" y="1027">проспект</text>
            <text class="s113" x="1168" y="1073">Текстильщики</text>
            <text class="s112" x="1168" y="1120">Кузьминки</text>
            <text class="s111" x="1168" y="1168">Рязанский</text>
            <text class="s111" x="1168" y="1180">проспект</text>
            <text class="s110" x="1168" y="1227">Выхино</text>
            <text class="s123" x="299" y="562">Полежаевская</text>
            <text class="s131" x="1219" y="647">Перово</text>
            <text class="s133" x="1130" y="736">Авиамоторная</text>
            <text class="s134" x="1088" y="788">Площадь Ильича</text>
            <text class="s135" x="981" y="834">Марксистская</text>
            <text class="s240" x="159" y="938">Минская</text>
            <text class="s241" x="160" y="968">Ломоносовский</text>
            <text class="s241" x="160" y="980">проспект</text>
            <text class="s242" x="159" y="1006">Раменки</text>
            <text class="s243" x="159" y="1054">Мичуринский проспект</text>
            <text class="s244" x="159" y="1104">Озерная</text>
            <text class="s245" x="159" y="1154">Говорово</text>
            <text class="s246" x="159" y="1204">Солнцево</text>
            <text class="s247" x="159" y="1254">Боровское шоссе</text>
            <text class="s248" x="159" y="1304">Новопеределкино</text>
            <text class="s249" x="159" y="1354">Рассказовка</text>

            <text class="s161" x="619" y="79">Алтуфьево</text>
            <text class="s160" x="619" y="122">Бибирево</text>
            <text class="s159" x="619" y="166">Отрадное</text>
            <text class="s158 s228" x="619" y="203">Владыкино</text>
            <text class="s156" x="536" y="334">Тимирязевская</text>
            <text class="s155" x="538" y="370">Дмитровская</text>
            <text class="s154" x="557" y="408">Савёловская</text>
            <text class="s153" x="575" y="432">Менделеевская</text>
            <text class="s151" x="627" y="668">Чеховская</text>
            <text class="s149" x="660" y="947">Полянка</text>
            <text class="s148" x="657" y="994">Серпуховская</text>
            <text class="s147" x="660" y="1092">Тульская</text>
            <text class="s146" x="681" y="1220">Нагатинская</text>
            <text class="s145" x="663" y="1265">Нагорная</text>
            <text class="s143" x="663" y="1362">Севастопольская</text>
            <text class="s142" x="663" y="1393">Чертановская</text>
            <text class="s141" x="663" y="1427">Южная</text>
            <text class="s140" x="664" y="1462">Пражская</text>
            <text class="s138" x="664" y="1534">Аннино</text>
            <text class="s234 s157" x="598" y="252">Петровско-Разумовская</text>
            <text class="s233" x="677" y="294">Фонвизинская</text>
            <text class="s232" x="694" y="353">Бутырская</text>
            <text class="s178" x="694" y="377">Марьина Роща</text>
            <text class="s176" x="694" y="518">Трубная</text>
            <text class="s170" x="999" y="1136">Кожуховская</text>
            <text class="s169" x="999" y="1179">Печатники</text>
            <text class="s168" x="999" y="1222">Волжская</text>
            <text class="s167" x="999" y="1266">Люблино</text>
            <text class="s166" x="999" y="1309">Братиславская</text>
            <text class="s165" x="999" y="1353">Марьино</text>
            <text class="s164" x="999" y="1398">Борисово</text>
            <text class="s163" x="999" y="1441">Шипиловская</text>
            <text class="s162" x="999" y="1484">Зябликово</text>
            <text class="s192" x="537" y="1507">Лесопарковая</text>
            <text class="s180" x="725" y="1330">Варшавская</text>
            <text class="s62" x="102" y="587">Пионерская</text>
            <text class="s64" x="101" y="662">Багратионовская</text>
            <text class="s65" x="103" y="698">Фили</text>
            <text class="s46 s61" x="87" y="531">Кунцевская</text>
            <text class="s105" x="853" y="316">ВДНХ</text>
            <text class="s104" x="853" y="350">Алексеевская</text>
            <text class="s222" x="1195" y="586">Соколиная</text>
            <text class="s222" x="1195" y="599">Гора</text>
            <text class="s190" x="1167" y="1271">Лермонтовский</text>
            <text class="s190" x="1167" y="1284">проспект</text>
            <text class="s189" x="1169" y="1329">Жулебино</text>
            <text class="s197" x="1168" y="1379">Котельники</text>
            <text class="s183" x="525" y="1612">Улица Скобелевская</text>
            <text class="s185" x="320" y="1612">Улица Горчакова</text>
            <text class="s184" x="390" y="1580">Бульвар Адмирала Ушакова</text>
            <text class="s186" x="229" y="1579">Бунинская аллея</text>
            <text class="s188" x="66" y="273">Пятницкое Шоссе</text>
            <text class="s47" x="84" y="868">Славянский</text>
            <text class="s47" x="84" y="880">бульвар</text>
            <text class="s63" x="101" y="623">Филёвский парк</text>
            <text class="s137" x="663" y="1563">Бульвар Дмитрия Донского</text>
            <text class="s48 s194" x="187" y="874">Парк</text>
            <text class="s48 s194" x="187" y="886">Победы</text>
            <text class="s144" x="663" y="1310">Нахимовский проспект</text>
            <text class="s139" x="663" y="1499">Улица Академика Янгеля</text>
            <text class="s95" x="492" y="1120">Ленинский проспект</text>
            <text class="s132 s221" x="1161" y="704">Шоссе Энтузиастов</text>
            <text class="s124" x="225" y="464">Октябрьское Поле</text>
          </g>
          <g class="uc">
            <text class="s237" x="234" y="602">Хорошевская</text>
            <text class="s238" x="386" y="508">ЦСКА</text>
            <text class="s239" x="408" y="446">Петровский парк</text>
          </g>
        </g>
      </g>
      <defs>
        <g id="rzd" transform="scale(0.5)">
          <polygon fill="#00B253" points="19.8382,33.7507 14.5137,33.7507 10.8817,38.5638 8.06591,38.5638 12.6617,32.2376 9.98642,28.7321 9.98642,7.48716 13.3172,5.56428 15.1314,5.56428 15.1314,3.49968 19.8382,3.49968 24.545,3.49968 24.545,5.56428 26.3592,5.56428 29.69,7.48716 29.69,28.7321 27.0147,32.2376 31.6105,38.5638 28.7947,38.5638 25.1627,33.7507 "></polygon>
          <rect fill="white" x="12.7" y="10.5" width="14.5" height="6.6"></rect>
          <rect fill="white" x="12.7" y="25" width="3.3" height="2.2"></rect>
          <rect fill="white" x="24" y="25" width="3.3" height="2.2"></rect>
        </g>
      </defs>
      <defs>
        <g id="air" transform="scale(0.5)">
          <polygon fill="#E60005" points="6.95447,31.6884 7.92417,32.6569 10.1282,31.2396 13.4294,35.6865 16.2428,32.8554 15.67,28.7722 23.8446,20.763 26.13,38.9004 31.2951,33.6396 31.4487,13.2357 35.3924,9.37341 36.0397,8.74033 36.0397,8.73206 36.9598,7.50842 37.3011,5.95996 36.9291,4.35245 35.8495,3.03904 34.366,2.36225 33.6255,2.28784 31.8077,2.76856 30.6738,3.77252 26.3769,8.16276 5.97177,8.40371 0.712219,13.4814 18.8496,15.768 10.8392,23.9414 6.56824,23.3698 3.92606,26.1832 8.32221,29.3226 "></polygon>
        </g>
      </defs>
      <g class="icons">
        <g>
          <title>от м.Котельники до а/п Жуковский автобус 441Э</title>
          <use x="1128" y="1366" xlink:href="#air" id="air8"></use>
        </g>
        <g>
          <title>от ст.метро "Речной вокзал" до Шереметьево: Автобус №851,
            маршрутка №949</title>
          <use x="339" y="167" xlink:href="#air" id="air6"></use>
        </g>
        <g>
          <title>От Планерной до Шереметьево Автобус №817</title>
          <use x="182" y="166" xlink:href="#air" id="air5"></use>
        </g>
        <g><title>Аэроэкспресс до Шереметьево</title>
          <use x="417" y="560" xlink:href="#air" id="air1"></use>
        </g>
        <g><title>Аэроэкспресс до Внуково</title>
          <use x="386" y="779" xlink:href="#air" id="air3"></use>
        </g>
        <g><title>От Юго-Западной до Внуково автобусы №№611,611С</title>
          <use x="282" y="1243" xlink:href="#air" id="air4"></use>
        </g>
        <g><title>от Домодедовской до Домодедово Маршрутка №308</title>
          <use x="790" y="1452" xlink:href="#air" id="air7"></use>
        </g>
        <g><title>Аэроэкспресс в Домодедово</title>
          <use x="852" y="969" xlink:href="#air" id="air2"></use>
        </g>
        <g><title>Белорусский вокзал</title>
          <use x="440" y="559" xlink:href="#rzd" id="rzd2"></use>
        </g>
        <g><title>Беговая</title>
          <use x="335" y="565" xlink:href="#rzd" id="rzd6"></use>
        </g>
        <g><title>Фили</title>
          <use x="66" y="685" xlink:href="#rzd" id="rzd7"></use>
        </g>
        <g><title>Кунцево</title>
          <use x="30" y="518" xlink:href="#rzd" id="rzd8"></use>
        </g>
        <g><title>Киевский вокзал</title>
          <use x="414" y="778" xlink:href="#rzd" id="rzd22"></use>
        </g>
        <g><title>ЗИЛ</title>
          <use x="625" y="1079" xlink:href="#rzd" id="rzd23"></use>
        </g>
        <g><title>Нижние Котлы</title>
          <use x="662" y="1205" xlink:href="#rzd" id="rzd24"></use>
        </g>
        <g><title>Коломенское</title>
          <use x="706" y="1316" xlink:href="#rzd" id="rzd25"></use>
        </g>
        <g><title>Царицыно</title>
          <use x="792" y="1371" xlink:href="#rzd" id="rzd16"></use>
        </g>
        <g><title>Павелецкий вокзал</title>
          <use x="831" y="969" xlink:href="#rzd" id="rzd17"></use>
        </g>
        <g><title>Курский вокзал</title>
          <use x="996" y="651" xlink:href="#rzd" id="rzd9"></use>
        </g>
        <g><title>Электрозаводская</title>
          <use x="1094" y="554" xlink:href="#rzd" id="rzd12"></use>
        </g>
        <g><title>Новая</title>
          <use x="1147" y="706" xlink:href="#rzd" id="rzd13"></use>
        </g>
        <g><title>Серп и Молот</title>
          <use x="1098" y="758" xlink:href="#rzd" id="rzd10"></use>
        </g>
        <g><title>Текстильщики</title>
          <use x="1132" y="1059" xlink:href="#rzd" id="rzd15"></use>
        </g>
        <g><title>Выхино</title>
          <use x="1132" y="1214" xlink:href="#rzd" id="rzd14"></use>
        </g>
        <g><title>Новогиреево</title>
          <use x="1263" y="592" xlink:href="#rzd" id="rzd11"></use>
        </g>
        <g><title>Казанский, Ленинградский, Ярославский вокзалы</title>
          <use x="935" y="508" xlink:href="#rzd" id="rzd18"></use>
        </g>
        <g><title>Савёловский вокзал</title>
          <use x="523" y="413" xlink:href="#rzd" id="rzd1"></use>
        </g>
        <g><title>Дмитровская</title>
          <use x="500" y="358" xlink:href="#rzd" id="rzd5"></use>
        </g>
        <g><title>Тимирязевская</title>
          <use x="500" y="321" xlink:href="#rzd" id="rzd4"></use>
        </g>
        <g><title>Петровско-Разумовская</title>
          <use x="570" y="263" xlink:href="#rzd" id="rzd3"></use>
        </g>
        <g><title>Тушино</title>
          <use x="187" y="241" xlink:href="#rzd" id="rzd21"></use>
        </g>
        <g><title>Ленинградская</title>
          <use x="380" y="291" xlink:href="#rzd" id="rzd20"></use>
        </g>
        <g><title>Рижский вокзал,пл.Ржевская</title>
          <use x="816" y="368" xlink:href="#rzd" id="rzd19"></use>
        </g>
        <g><title>Окружная</title>
          <use x="570" y="202" xlink:href="#rzd" id="rzd26"></use>
        </g>
        <g><title>НАТИ</title>
          <use x="504" y="263" xlink:href="#rzd" id="rzd27"></use>
        </g>
        <g><title>Северянин</title>
          <use x="901" y="222" xlink:href="#rzd" id="rzd28"></use>
        </g>
        <g><title>Фрезер</title>
          <use x="1172" y="742" xlink:href="#rzd" id="rzd29"></use>
        </g>
        <g><title>Карачарово</title>
          <use x="1157" y="817" xlink:href="#rzd" id="rzd30"></use>
        </g>
        <g><title>Калитники</title>
          <use x="1142" y="863" xlink:href="#rzd" id="rzd31"></use>
        </g>
        <g><title>Тестовская</title>
          <use x="187" y="693" xlink:href="#rzd" id="rzd32"></use>
        </g>
        <g><title>Покровско-Стрешнево</title>
          <use x="269" y="408" xlink:href="#rzd" id="rzd33"></use>
        </g>
      </g>
    </svg>

    <div style="display: none;" id="filterStationsList">

    </div>
  </div>
</template>

<style scoped>

</style>


