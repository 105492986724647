<script setup>
import { ref, computed, watch, defineProps, defineEmits, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ minValue: null, maxValue: null })
  },
  label: String
});
const emit = defineEmits(['update:modelValue']);

const internalValue = ref({ ...props.modelValue });
const showDropdown = ref(false);

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const closeDropdown = () => {
  showDropdown.value = false;
};

const formattedValue = computed(() => {
  const formatNumber = (num) => {
    if (num === null || num === undefined) return '';
    if (num >= 1e9) return `${(num / 1e9).toFixed(1)} млрд`;
    if (num >= 1e6) return `${(num / 1e6).toFixed(1)} млн`;
    if (num >= 1e3) return `${(num / 1e3).toFixed(1)} тыс`;
    return num.toString();
  };

  if (internalValue.value.minValue === null && internalValue.value.maxValue === null) {
    return props.label || 'Стоимость, Р';
  }

  if (internalValue.value.minValue === null) {
    return `до ${formatNumber(internalValue.value.maxValue)}`;
  }
  if (internalValue.value.maxValue === null) {
    return `от ${formatNumber(internalValue.value.minValue)}`;
  }

  return `${formatNumber(internalValue.value.minValue)} - ${formatNumber(internalValue.value.maxValue)}`;
});

watch(
    internalValue,
    (newValue) => {
      if (
          newValue.minValue !== props.modelValue.minValue ||
          newValue.maxValue !== props.modelValue.maxValue
      ) {
        emit('update:modelValue', newValue);
      }
    },
    { deep: true }
);

watch(
    () => props.modelValue,
    (newValue) => {
      if (
          newValue.minValue !== internalValue.value.minValue ||
          newValue.maxValue !== internalValue.value.maxValue
      ) {
        internalValue.value = { ...newValue };
      }
    },
    { deep: true }
);

const dropdownRef = ref(null);

const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    closeDropdown();
  }
};

onMounted(() => {
  document.addEventListener('mousedown', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('mousedown', handleClickOutside);
});
</script>

<template>
  <div ref="dropdownRef">
    <div class="price-range-input" @click="toggleDropdown">
      {{ formattedValue }}
    </div>
    <div v-if="showDropdown" class="dropdown">
      <input
          type="number"
          v-model.number="internalValue.minValue"
          placeholder="От"
      />
      <input
          type="number"
          v-model.number="internalValue.maxValue"
          placeholder="До"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.price-range-input {
  cursor: pointer;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  padding: 0 4px;
  width: 157px;
  height: 30px;
  border: 1px solid #8a8a8a;
  border-radius: 5px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown {
  position: absolute;
  z-index: 1000;
  margin-top: 8px;
  display: flex;
  gap: 8px;
  width: 155px;
  height: 50px;
  background: white;
  padding: 9px 5px 5px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
 input {
   width: 60px;
   height: 30px;
   margin-right: 10px;
   border: 1px solid #8a8a8a;
   border-radius: 2px;
   padding-left: 3px;
 }
}
</style>
