<script setup lang="ts">
import { onMounted, ref } from "vue";
import MainFilter from "@/widgets/main-filter/ui/main-filter.vue";
import CardList from "@/widgets/card-list/ui/card-list.vue";
import {RealtyObject, useApiRequestCards} from "@/shared/api/gproperty-service";
const { result, isLoading, error, makeRequest } = useApiRequestCards();
const cards = ref<RealtyObject[]>([])

onMounted(async () => {
  const params = {
    best_offer: true
  }
  await makeRequest(params)
  cards.value = {...result.value?.result?.realty_object_list as RealtyObject[]}

})
</script>

<template>
  <div class="home">
    Аренда и продажа недвижимости в Москве
  </div>
  <MainFilter :isLoading="isLoading" :cards="cards" />
  <div class="red-title">
    ЛУЧШИЕ ПРЕДЛОЖЕНИЯ
  </div>
  <CardList :isLoading="isLoading" :cards="cards" />

</template>
<style scoped>
@media (max-width: 500px) {
  .home {
    height: 400px !important;
    background-position-y: 0 !important;
  }
}
.home{
  background-image: url(../../../assets/image/lg2.jpg) !important;
  height: 510px;
  margin-top: -131px;
  background-position-y: -216px;
  background-position-x: 0px;
  background-size: cover;

  font-size: 36px;
  font-family: 'Gilroy', sans-serif;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.red-title {
  font-size: 28px;
  font-family: 'Gilroy', sans-serif;
  color: #ff3b3b;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: 110px;
}
</style>
