<script setup lang="ts"></script>

<template>
  <div class="top-wr" style="height: 350px">
    <div class="home">
      <h2 class="main-title" style="margin-top: 60px;">О компании</h2>
      <div style="font-size: 14px; color: white; text-align: center">
        <a href="/">Главная</a>
        &gt; О компании
      </div>
    </div>
  </div>
  <div
    style="
      height: 100%;
      text-align: left;
      padding-top: 50px;
      padding-bottom: 30px;
      width: 1040px;
      margin-left: auto;
      margin-right: auto;
    "
    class="about-media"
  >
    <div>
      «Актив Проперти» — это консалтинговая компания, основной деятельностью которой является продажа готового арендного бизнеса.
    </div>
    <div style="margin-top: 20px">
      Компания является надежным и честным бизнес-партнером, что обеспечивается
      принципом прозрачности ведения дел, а также пристальным вниманием к
      соблюдению конфиденциальности информации об участниках сотрудничества. Для
      нас важна наша деловая репутация. «ГАММА» берет на себя полную
      ответственность и принимает гарантийные обязательства по соблюдению
      качества предоставляемых услуг.
    </div>
  </div>
</template>

<style scoped>
.home{
  background-image: url(../../../assets/image/lg2.jpg) !important;
  height: 370px;
  margin-top: -131px;
  background-position-y: -216px;
  background-position-x: 0px;
  background-size: cover;

  font-size: 36px;
  font-family: 'Gilroy', sans-serif;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
</style>
