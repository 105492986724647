import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "room-cards-wr" }

import Card from "@/entities/card/ui/card.vue";
import {onMounted, ref} from "vue";
import {RealtyObject, useApiRequestCards} from "@/shared/api/gproperty-service";
import CardListSkeleton from "@/shared/ui/card-list-skeleton.vue";

type Props = {
  cards: RealtyObject[];
  isLoading: boolean
};


export default /*@__PURE__*/_defineComponent({
  __name: 'card-list',
  props: {
    cards: {},
    isLoading: { type: Boolean }
  },
  setup(__props: any) {


const props = __props;



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!props.isLoading)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(props.cards, (card) => {
          return (_openBlock(), _createBlock(Card, {
            commonData: card,
            key: card.id
          }, null, 8, ["commonData"]))
        }), 128))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(7, (i) => {
          return _createVNode(CardListSkeleton)
        }), 64))
  ]))
}
}

})