import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import HeaderWidget from "@/widgets/header-widget/ui/header-widget.vue";
import AboutPage from "@/pages/about-page/ui/about-page.vue";
import MainPage from "@/pages/main-page/ui/main-page.vue";
import FooterWidget from "@/widgets/footer-widget/ui/footer-widget.vue";
import FooterLine from "@/widgets/footer-line/ui/footer-line.vue";
import {useRoute} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderWidget, {
      isInner: _unref(route).path !== '/'
    }, null, 8, ["isInner"]),
    _createVNode(_component_router_view),
    _createVNode(FooterWidget),
    _createVNode(FooterLine)
  ], 64))
}
}

})