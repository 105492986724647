import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainPage from "../pages/main-page/ui/main-page.vue";
import RealtyObject from "@/pages/realty-object/ui/realty-object.vue";
import AboutPage from "@/pages/about-page/ui/about-page.vue";
import ContactsPage from "@/pages/contacts-page/ui/contacts-page.vue";
import Partners from "@/pages/partners/ui/partners.vue";
import CatalogPage from "@/pages/catalog-page/ui/catalog-page.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: MainPage,
  },
  {
    path: "/realty-object/:id",
    name: "realty-object",
    component: RealtyObject,
  },
  {
    path: "/about",
    name: "about",
    component: AboutPage,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: ContactsPage,
  },
  {
    path: "/partners",
    name: "partners",
    component: Partners,
  },
  {
    path: "/catalog",
    name: "catalog",
    component: CatalogPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
