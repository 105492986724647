<script setup lang="ts">


import {RealtyObject, useApiRequestCards, useApiRequestGetStreet} from "@/shared/api/gproperty-service";
import {onMounted, ref, watch} from "vue";
import Card from "@/entities/card/ui/card.vue";
import CardListSkeleton from "@/shared/ui/card-list-skeleton.vue";
import MetroModal from "@/features/metro-modal/ui/metro-modal.vue";
import MapModal from "@/features/map-modal/ui/map-modal.vue";
import InputDropdown from "@/shared/components/input-dropdown/ui/input-dropdown.vue";
import Pagination from "@/shared/components/pagination/ui/pagination.vue";
import {useRoute, useRouter} from "vue-router";
import {fi} from "vuetify/locale";

const { result, isLoading, error, makeRequest } = useApiRequestCards();
const { result: resultStreet, isLoading: isLoadingStreet, makeRequest: getStreet } = useApiRequestGetStreet();

const isOpenMetroModal = ref(false)
const isMapModalOpen = ref(false)

const route = useRoute();
const router = useRouter()

const realtyObjList = ref<RealtyObject[]>([])
const streetList = ref<string[]>([])

type defaultFiltersType = {
  purpose: null | string,
  premises_type: null | string,
  region: null | string,
  street: null | string,
  underground: null | string[]
}
const defaultFilters = {
  purpose: null,
  premises_type: null,
  region: null,
  street: null,
  underground: null
}


const areaRange = ref({
  minValue: null,
  maxValue: null
});

const priceRange = ref({
  minValue: null,
  maxValue: null
});

const currentPage = ref(1)
const pageQuantity = ref(1)
const filters = ref<defaultFiltersType>({...defaultFilters})

const changePage = async (currentPageValue: number) => {
  await makeRequest({page_number: currentPageValue})
  realtyObjList.value = {...result.value?.result?.realty_object_list as RealtyObject[]}
  await router.push({
    query: {
      page_number: currentPageValue
    }
  });
  currentPage.value = currentPageValue
}


const onSearchClick = async () => {
  currentPage.value = 1
  await router.push({
    query: {
      page_number: 1,
      ...filters.value,
      price_from: priceRange.value.minValue,
      price_to: priceRange.value.maxValue,
      total_area_from: areaRange.value.minValue,
      total_area_to: areaRange.value.maxValue
    }
  });

}

onMounted(async () => {
  await makeRequest({
    page_number: route.query.page_number || 1,
        ...route.query
  })

  filters.value.purpose = route.query.purpose || null
  filters.value.premises_type = route.query.premises_type || null
  filters.value.region = route.query.region || null
  filters.value.street = route.query.street || null
  priceRange.value.minValue = route.query.price_from ?  Number(route.query.price_from) : null
  priceRange.value.maxValue = route.query.price_to ? Number(route.query.price_to) : null
  areaRange.value.minValue = route.query.total_area_from ?  Number(route.query.total_area_from) : null
  areaRange.value.maxValue = route.query.total_area_to ? Number(route.query.total_area_to) : null

  currentPage.value = Number(route.query.page_number || 1)

  realtyObjList.value = {...result.value?.result?.realty_object_list as RealtyObject[]}
  pageQuantity.value = result.value?.result?.page_quantity
})

const showAll = async () => {
  await router.push({
    query: {}
  });
  currentPage.value = 1
}

const showTorg = async () => {
  await router.push({
    query: {
      premises_type: 'Торговое помещение'
    }
  });
  currentPage.value = 1
}

const showOfficce = async () => {
  await router.push({
    query: {
      premises_type: 'Офисное помещение'
    }
  });
  currentPage.value = 1
}

const showHouse = async () => {
  await router.push({
    query: {
      premises_type: 'Здание'
    }
  });
  currentPage.value = 1
}

watch(() => route.query, async (newQuery) => {
  filters.value.purpose = newQuery.purpose || null
  filters.value.premises_type = newQuery.premises_type || null
  filters.value.region = newQuery.region || null
  filters.value.street = newQuery.street || null
  priceRange.value.minValue = newQuery.price_from ?  Number(newQuery.price_from) : null
  priceRange.value.maxValue = newQuery.price_to ? Number(newQuery.price_to) : null
  areaRange.value.minValue = newQuery.total_area_from ?  Number(newQuery.total_area_from) : null
  areaRange.value.maxValue = newQuery.total_area_to ? Number(newQuery.total_area_to) : null
  await makeRequest({
    page_number: newQuery.page_number || 1,
    ...newQuery
  })
  realtyObjList.value = {...result.value?.result?.realty_object_list as RealtyObject[]}
  currentPage.value = newQuery.page_number ? Number(newQuery.page_number) : 1
  pageQuantity.value = result.value?.result?.page_quantity

}, { immediate: true, deep: true });

const onInputStreet = async ({target}: any) => {
  console.log('onInputStreet', target.value)
  if (target.value.length > 3) {
    await getStreet(target.value)
    streetList.value = resultStreet.value?.result
  } else  if (target.value.length === 0) {
    streetList.value = []
  }
}

const onSelectStreet = (e: any) => {
  console.log('onSelectStreet', e.target.innerText)
  filters.value.street = e.target.innerText
}

const onBlurStreet = () => {
  setTimeout(() => {
    streetList.value = []
  }, 200)
}

const onSubmitMetro = (value: string[]) => {
  console.log('onSubmitMetro: ', value)
  if (value.length) {
    filters.value.underground = value
  } else {
    filters.value.underground = null
  }
}

</script>

<template>
  <div class="filter-inside-wr">

    <div class="filter-inside">
      <div class="catalog-btn-wr">
        <div class="catalog-btn-inside" id="allOffers" @click="showAll">Все предложения</div>
        <div class="catalog-btn-inside" id="commercialPremises" @click="showTorg">Торговое помещение</div>
        <div class="catalog-btn-inside" id="offPremises" @click="showOfficce">Офисное помещение</div>
        <div class="catalog-btn-inside" id="building"  @click="showHouse">Здание</div>
      </div>
      <div style="display: flex; margin-top: 15px; cursor: pointer;" class="filter-inside-params">
        <v-select
            v-model="filters.purpose"
            class="custom-select"
            placeholder="Цель"
            :items="['Продажа', 'Аренда', 'Арендный бизнес']"
            variant="outlined"
        >
          <template #item="{ item }">
            <div class="custom-item" @click="filters.purpose = item.title">
              <span>{{item.title}}</span>
            </div>
          </template>
          <template #selection="{ item, index }">
            <div class="custom-selection">
              <span>{{ item.title }}</span>
            </div>
          </template>
        </v-select>
        <InputDropdown v-model="areaRange" label="Площадь, м²" style="margin-right: 8px" />
        <InputDropdown v-model="priceRange" label="Стоимость, ₽" style="margin-right: 8px" />

        <v-select
            v-model="filters.premises_type"
            placeholder="Тип помещения"
            class="custom-select"
            :items="['Торговое помещение', 'Здание', 'Офисное помещение']"
            variant="outlined"
        >
          <template #item="{ item }">
            <div class="custom-item" @click="filters.premises_type = item.title">
              <span>{{item.title}}</span>
            </div>
          </template>
          <template #selection="{ item, index }">
            <div class="custom-selection">
              <span>{{ item.title }}</span>
            </div>
          </template>
        </v-select>
        <v-select
            v-model="filters.region"
            placeholder="Любой город"
            class="custom-select"
            :items="['Любой город', 'Москва', 'Подмосковье']"
            variant="outlined"
        >
          <template #item="{ item }">
            <div class="custom-item" @click="filters.region = item.title">
              <span>{{item.title}}</span>
            </div>
          </template>
          <template #selection="{ item, index }">
            <div class="custom-selection">
              <span>{{ item.title }}</span>
            </div>
          </template>
        </v-select>
        <div class="main-filter-list-item main-filter-list-item-media" id="metroBtn" @click="() => isOpenMetroModal = true">{{filters.underground?.length ? `Выбрано станций: ${filters.underground.length}` : 'Станция метро'}}</div>
        <div style="width: 157px; margin-right: 8px;" id="hintsWr">
          <input v-model="filters.street" @input="onInputStreet" @blur="onBlurStreet" style="outline: none;" class="main-filter-list-item main-filter-list-item-media" type="text" placeholder="Улица" id="streetControl">
          <ul id="hints" v-if="streetList?.length" style="display: block;" class="street-list">
            <li @click="onSelectStreet" v-for="street in streetList" :key="street" class="street-item">{{street}}</li>
          </ul>
        </div>
        <div class="main-filter-list-item main-filter-list-item-media" style="position: relative; display: none;" id="paybackWr">
          <span id="payback" style="cursor: pointer; width: 100%; display: block;">Окупаемость, лет</span>
          <span class="caret" style="position: absolute; right: 5px; bottom: 13px;"></span>
          <div id="paybackModal" style="display: flex; width: 155px; display: none; height: 50px; background: white;position: absolute; bottom: -55px; padding: 5px; text-align: center; z-index: 20; border-radius: 4px; border: 1px solid rgba(0,0,0,.15); left: 0; padding-top: 9px;">
            <input id="paybackFrom" style="width: 60px; height: 30px; margin-right: 10px" type="number" placeholder="От">
            <input id="paybackTo" style="width: 60px; height: 30px;" type="number" placeholder="До">
          </div>
        </div>
      </div>
      <div class="catalog-btn-bot-wr">
        <button class="catalog-btn-on-map onmap-btn" @click="() => isMapModalOpen = true">
          На карте
        </button>
        <button id="searchBtn" class="catalog-btn-search" @click="onSearchClick">
          Поиск
        </button>
      </div>
    </div>
  </div>
  <div class="red-title red-title-media" style="text-align: left; margin-top: 20px; width: 1120px;" id="allOffersCatalog">
    все предложения
  </div>
  <div style="text-align: left; margin-top: 20px; width: 1120px; margin-left: auto;margin-right: auto; margin-bottom: -25px;" id="cardsOnPage">
    Найдено предложений: {{ result?.result?.object_quantity}}
  </div>
  <div class="room-cards-wr">
    <template v-if="!isLoading">
      <Card v-for="card in realtyObjList" :commonData="card" :key="card.id" />
    </template>
    <template v-else>
      <CardListSkeleton v-for="i in 7"/>
    </template>
  </div>
  <div v-if="pageQuantity > 1" style="display: flex; width: 100%; justify-content: center; margin-bottom: 30px">
    <Pagination :page-quantity="pageQuantity" :initialPage="currentPage" @change-page="changePage" />
  </div>
  <MetroModal v-if="isOpenMetroModal" @close="() => isOpenMetroModal = false" @submit="onSubmitMetro" />
  <MapModal v-if="isMapModalOpen" :isLoading="isLoading" :cards="realtyObjList" @close="isMapModalOpen = false" />

</template>
<style scoped>

@media (max-width: 780px) {
  .filter-inside-params {
    display: flex !important;
    /*flex-direction: column;*/
    gap: 20px;
  }
}
@media (max-width: 500px) {
  .filter-inside-params {
    display: flex !important;
    /*flex-direction: column;*/
    gap: 20px;
  }
}
.filter-wr {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding: 14px 20px;
  height: 169px;
  border-radius: 40px;
  background-color: white;
  margin-top: -50px;
  padding-top: 30px;
}

.bot-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
::v-deep .v-input__control::after {
  content: '▼'; /* Символ стрелочки */
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 10px;
  opacity: 30%;
  transform: translateY(-50%);
  pointer-events: none; /* Чтобы не было взаимодействия с стрелочкой */
  color: #000; /* Цвет стрелочки */
}


.filters {
  display: flex;
  gap: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

::v-deep .v-input__control {
  position: relative;
  height: 30px;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  outline: none;
  border: none;
}

:deep(.v-input__details) {
  display: none;

}

:deep(.v-field__input) {
  height: 30px;
  padding: 3px 0 0 10px;
  outline: none;
  border: none;
  width: 150px;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
}

.custom-select {
  font-size: 14px;
  width: 157px;
  height: 30px;
  border: 1px solid #8a8a8a;
  border-radius: 5px;
  font-family: 'Gilroy', sans-serif;
  margin-right: 8px;
  opacity: 100%;
}

.custom-item {
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  height: 42px;
  border-bottom: 1px solid #dcdcdc;
  box-sizing: border-box;
  padding-top: 11px;
  padding-left: 24px;
  background-color: white;
  color: #000;
}

.custom-item:hover {
  background-color: #ff3b3b;
  color: white;
  cursor: pointer;
}

.custom-selection {
  color: black;
  margin-top: -30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.street-item {
  padding: 10px;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  height: 42px;
  border-bottom: 1px solid #dcdcdc;
  box-sizing: border-box;
  background-color: white;
  color: #000;
  transition: 0.3s ease;
}

.street-item:hover {
  color: white;
  background-color: #ff3b3b;
}

.street-list {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
}
</style>
