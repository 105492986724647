<template>
  <div class="skeleton-card">
    <div class="skeleton-image"></div>
    <div class="skeleton-content">
      <div class="skeleton-text skeleton-title"></div>
      <div class="skeleton-text skeleton-subtitle"></div>
      <div class="skeleton-icon-group">
        <div class="skeleton-icon"></div>
        <div class="skeleton-text skeleton-info"></div>
      </div>
      <div class="skeleton-icon-group">
        <div class="skeleton-icon"></div>
        <div class="skeleton-text skeleton-info"></div>
      </div>
      <div class="skeleton-icon-group">
        <div class="skeleton-icon"></div>
        <div class="skeleton-text skeleton-info"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.skeleton-card {
  width: 263px;
  height: 400px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background-color: #e0e0e0;
  border-radius: 8px;
  animation: shimmer 1.5s infinite;
}

.skeleton-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-text {
  width: 100%;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: shimmer 1.5s infinite;
}

.skeleton-title {
  height: 20px;
  width: 70%;
}

.skeleton-subtitle {
  height: 18px;
  width: 40%;
}

.skeleton-icon-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.skeleton-icon {
  width: 24px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 50%;
  animation: shimmer 1.5s infinite;
}

.skeleton-info {
  width:65%;
  height: 16px;
}

@keyframes shimmer {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.skeleton-image,
.skeleton-text,
.skeleton-icon {
  background: linear-gradient(
      to right,
      #e0e0e0 0%,
      #f0f0f0 50%,
      #e0e0e0 100%
  );
  background-size: 200% 100%;
}
</style>
