<script setup lang="ts">
import {onMounted, ref} from "vue";

type Props = {
  name: string;
  options: { title: string; action: () => void }[];
  inBurger?: boolean
};

const props = withDefaults(defineProps<Props>(), {
  name: "Кнопка",
  inBurger: false
});

const isOpen = ref(false);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const handleAction = (action: any) => {
  isOpen.value = false;
  console.log('ACTION')
  if (typeof action === "function") {
    action();
  }
};

onMounted(() => {
  document.addEventListener('click', (e) => {
    // @ts-ignore
    if (e.target?.offsetParent?.attributes?.id?.value !== id) {
      isOpen.value = false
    }
  })
})

const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
</script>

<template>
  <div class="dropdown" :class="{'dropdown-in-burger': inBurger}" :id="id">
    <button @click="toggleDropdown" class="dropdown-button" >{{props.name}} <span class="caret"></span></button>
    <div v-if="isOpen" class="dropdown-menu">
      <ul>
        <li
          v-for="(option, index) in props.options"
          :key="index"
          @click="handleAction(option.action)"
        >
          {{ option.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dropdown-in-burger {
  text-align: left;
  display: block !important;
  font-size: 16px !important;
  button {
    padding: 10px 20px 5px;
    border-bottom: 1px solid rgba(255, 59, 59, 0.6);
    width: max-content;
    text-align: left;
    color: rgb(255, 59, 59) !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px !important;
  }
  .dropdown-menu li {
    padding: 10px;
    cursor: pointer;
    margin: 0;
    color: black;
    font-size: 16px !important;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: transparent;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  z-index: 1000;
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  margin: 0;
  color: black;
  font-size: 10px;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}
</style>
